import React, {useEffect, useState} from 'react'
import {Grid, Typography} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux';
import actions from '../../redux/main/accounts/actions';
import AuthNavbar from "../Shared/Header/AuthNavbar";
import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {dashboardCard, mainMenus} from "../../helpers/constant";
import {Breadcrumb, Col} from "antd";
import {serverTimestamp} from "firebase/firestore";
import DashboardDrawer from './drawer';

const Dashboard = () => {
    const {offDocs} = useSelector(state => state.accountsReducer);
    const [visible, setVisible] = useState(false);
    const [from, setFrom] = useState(false);
    const [to, setTo] = useState(false);
    const [amount, setAmount] = useState(false);
    const [date, setDate] = useState(false);
    const [projectName, setProjectName] = useState(false);
    const [type, setType] = useState(false);
    const handleOpen = () =>{
        setVisible(true);
    };
    const handleClose = () => {
        setVisible(false);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({
            type: actions.GET_DOCS_START,
        });
    }
    const addAccounts = () => {
        dispatch({
            type: actions.CREATE_ACCOUNTS_START,
            payload: {
                from: from,
                to: to,
                amount: amount,
                date: date,
                projectName: projectName,
                type: type,
                createdAt: serverTimestamp()
            }
        });
        setVisible(false);
        dispatch({
            type: actions.GET_ACCOUNTS_START,
        });
    }
    console.log(offDocs)
    const docs = ['பத்திரம்', 'தாய் பத்திரம்', 'மூல பத்திரம்', 'அடங்கல்', 'பட்டா/சிட்டா', 'தனி பட்டா', 'Agri NOC', 'FMP', 'Blueprint', 'EC/வில்லங்க சான்றிதழ்', 'வாரிசு சான்றிதழ்', 'இறப்பு சான்றிதழ்']
    const classes = customStyles();
    return(
        <>
            <AuthNavbar value={'Dashboard'} />
            <div>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={3} md={3} sm={12} style={{width: '100%', backgroundColor: '#F7F7FF'}}>
                        <DashboardDrawer page={'Dashboard'} />
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} style={{padding: 20}}>
                        <Breadcrumb style={{
                            marginLeft: 20,
                            color: '#FF5733',
                            fontSize: '18px'
                        }}>
                            <Breadcrumb.Item>Dashboard</Breadcrumb.Item>
                        </Breadcrumb>
                        <div style={{padding: 10}}>
                            <Grid container className={classes.flexCenter}>
                                {dashboardCard.map(dashboard => (
                                    <Grid item lg={3} md={4} sm={12} style={{padding: 20}}>
                                        <div style={{backgroundColor: '#F0F8FF', padding: 10, borderRadius: 20}}>
                                            <Typography variant="h5" style={{fontSize:'16px', margin: '0.5em', color: '#00234B', fontWeight: 500}}>{dashboard.name}</Typography>
                                            <Typography variant="h3" style={{fontSize:'24px', margin: '0.5em', color: '#00234B', fontWeight: 600}}>{dashboard.value.toLocaleString()}</Typography>
                                        </div>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                        <div style={{padding: 10}}>
                            <Typography variant="h5" style={{fontSize:'18px', margin: '0.5em', color: '#202075', fontWeight: 500, padding: '10px 0'}}>Documents needed for Proper Registration</Typography>
                            {docs.map(doc => (
                                <Typography variant="h3" style={{fontSize:'16px', margin: '0.5em', color: '#000', padding: '0 10px'}}>=> {doc}</Typography>
                            ))}
                        </div>
                        <div style={{padding: '3rem 1.5rem'}}>
                            {offDocs.map(doc => (
                                <Grid item lg={3} md={4} sm={12} style={{padding: 20}}>
                                    <div style={{padding: 10, borderRadius: 20}}>
                                        <Typography variant="h5" style={{fontSize:'14px', margin: '0.5em', color: '#08A04B'}}>{doc.docType}</Typography>
                                        <Typography variant="h3" style={{fontSize:'24px', margin: '0.5em', color: '#202075'}}>{doc.name}</Typography>
                                        <iframe src={doc.link} width="640" height="825" allow="autoplay"></iframe>
                                    </div>
                                </Grid>
                            ))}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Dashboard

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: "40px",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
