import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import clsx from 'clsx'
import {Hidden} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerComponent from "./DrawerComponent";


const Navbar = () => {
    const classes = customStyles();
    const [visible, setVisible] = useState(false);

    const toggleDrawer = () => {
        setVisible(false)
    };
    const handleMobileDrawerOpen = () => {
        setVisible(true)
    };
    const menus = [
        {
            link: "/",
            name: "Home",
            variant: "default",
            button: false,
        },
        {
            link: "/discover",
            name: "Discover",
            variant: "default",
            button: false,
        },
        {
            link: "/login",
            name: "Login",
            variant: "default",
            button: false,
        },
        {
            link: "/signup",
            name: "Join the Club",
            variant: "default",
            button: true,
        },
    ];

    return (
        <div className={classes.root}>
            <AppBar position="fixed" className={classes.navBar}>
                <Toolbar>
                    {/*<Avatar variant='square' alt="" src={image} className={classes.logo} />*/}
                    <Typography variant='h5' className={classes.title}>
                        <span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>MATRIX</span>
                    </Typography> 
                    <Hidden smUp>
                        <IconButton
                            className={classes.menuButton}
                            onClick={handleMobileDrawerOpen}
                            aria-label="Open Navigation"
                        >
                            <MenuIcon color="primary" />
                        </IconButton>
                    </Hidden>
                    <Hidden smDown>
                        {menus.map(element => {
                            if (element.link) {
                                return (
                                    <Link
                                        key={element.name}
                                        to={element.link}
                                        className={clsx(classes.margin8a, classes.textDecoration, classes.fw600)}
                                        // onClick={handleMobileDrawerClose}
                                    >
                                        <Button
                                            disableRipple
                                            variant={element.variant}
                                            className={element.button ? classes.postBtn : classes.navLink}
                                        >
                                            {element.name}
                                        </Button>
                                    </Link>
                                );
                            }
                            return (
                                <Button
                                    color="secondary"
                                    size="large"
                                    onClick={element.onClick}
                                    key={element.name}
                                >
                                    {element.name}
                                </Button>
                            );
                        })}
                    </Hidden>
                </Toolbar>
            </AppBar>
            <DrawerComponent
                open={visible}
                toggleDrawerHandler={toggleDrawer}
            />
        </div>
    );
}

export default Navbar

const customStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    fw600: {
        fontWeight: 600,
    },
    navBar: {
        boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 4%)",
    },
    title: {
        flexGrow: 1,
        fontWeight: 600
    },
    menuButton: {
        borderRadius: 10,
        backgroundColor: "#f7f7f7",
        color: "#808080",
        fontSize: 10,

        '&:hover': {
            color: "#000",
            backgroundColor: "#f7f7f7",
        },
    },
    margin8a: {
        margin: "10px",
    },
    navLink: {
        color: "#182230",
        margin: 0,
        fontSize: "16px",
        fontWeight: 500,
        textDecoration: "none",
        textTransform: "capitalize",

        '&:hover': {
            color: "#006aff",
            backgroundColor: "inherit",
        }
    },
    textDecoration: {
        textDecoration: "none"
    },
    postBtn: {
        minWidth: 120,
        padding: '8px 20px',
        boxShadow: 'none',
        borderRadius: 20,
        color: '#000',
        fontWeight: 600,
        backgroundColor: '#FFC000',

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#FFF2CC',
        },
    },
})
