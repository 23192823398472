import {all, call, put, takeEvery} from 'redux-saga/effects';
import {collection, getDocs, getDoc, setDoc, doc, query, where, orderBy, limit} from "firebase/firestore";
import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import actions from './actions';
import {fbFirestore, fbStorage} from "../../../helpers/firebaseClient";

const auth = JSON.parse(window.sessionStorage.getItem('auth'));


export function* createProjects(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'projects', `${'RE' + randNum}`), params.payload))
        yield put({ type: actions.CREATE_PROJECTS_SUCCESS });
    } catch (error) {
        yield put({ type: actions.CREATE_PROJECTS_FAILURE });
        return error;
    }
}

export function* getProjects() {
    try {
        const response = yield call(() => getDocs(collection(fbFirestore, 'projects')))
        // const imgRef =  ref(fbStorage,`files/${v4()}`)
        // uploadBytes(imgRef,img).then(value=>{
        //     console.log(value)
        //     const resUrl = getDownloadURL(value.ref)            
        // })
        // console.log(resUrl)
        // const imgUrl = data=>[...data,url]
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        console.log(data)
        yield put({
            type: actions.GET_PROJECTS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({ type: actions.GET_PROJECTS_FAILURE });
        return error;
    }
}

export function* getProjectDetails(params) {
    try {
        const response = yield call(() => getDoc(doc(fbFirestore, 'projects', `${params.projectId}`)))
        const data = response.data()
        yield put({
            type: actions.GET_PROJECT_DETAILS_SUCCESS,
            payload: data,
            projectId: params.projectId
        });
        // const user = {currentUser: params.profileId}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
        // const user = {currentUser: 'BT2100003'}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
        yield put({ type: actions.GET_PROJECT_DETAILS_FAILURE });
        return error;
    }
}

export function* getFilteredExpeses(params) {
    try {
        const response = yield call (() => getDocs(query(collection(fbFirestore, 'expenses'),where('projectId', '==', `${params.projectId}`))));
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        console.log(data)
        yield put({
            type: actions.GET_PROJECT_EXPENSES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_PROJECT_EXPENSES_FAILURE });
        return error;
    }
}

export function* createProjectExpenses(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'expenses', `${'EX' + randNum}`), params.payload))
        yield put({ type: actions.CREATE_PROJECT_EXPENSE_SUCCESS });
    } catch (error) {
        yield put({ type: actions.CREATE_PROJECT_EXPENSE_FAILURE });
        return error;
    }
}

export function* getFilteredAccounts(params) {
    try {
        const response = yield call (() => getDocs(query(collection(fbFirestore, 'accounts'),where('projectId', '==', `${params.projectId}`))));
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        console.log(data)
        yield put({
            type: actions.GET_PROJECT_ACCOUNTS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_PROJECT_ACCOUNTS_FAILURE });
        return error;
    }
}

export function* createProjectAccounts(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'accounts', `${'AC' + randNum}`), params.payload))
        yield put({ type: actions.CREATE_PROJECT_ACCOUNTS_SUCCESS });
    } catch (error) {
        yield put({ type: actions.CREATE_PROJECT_ACCOUNTS_FAILURE });
        return error;
    }
}


export default function* mainSaga() {
    yield all([
        takeEvery(actions.CREATE_PROJECTS_START, createProjects),
        takeEvery(actions.GET_PROJECTS_START, getProjects),
        takeEvery(actions.GET_PROJECT_DETAILS_START, getProjectDetails),
        takeEvery(actions.GET_PROJECT_EXPENSES_START, getFilteredExpeses),
        takeEvery(actions.GET_PROJECT_ACCOUNTS_START, getFilteredAccounts),
        takeEvery(actions.CREATE_PROJECT_ACCOUNTS_START, createProjectAccounts),
        takeEvery(actions.CREATE_PROJECT_EXPENSE_START, createProjectExpenses),
    ]);
}
