import {all, call, put, takeEvery} from 'redux-saga/effects';
import {collection, getDocs, getDoc, setDoc, doc, query, where, orderBy, limit} from "firebase/firestore";
import actions from './actions';
import {fbFirestore} from "../../../helpers/firebaseClient";

const auth = JSON.parse(window.sessionStorage.getItem('auth'));


export function* createInvestments(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'investments', `${'RE' + randNum}`), params.payload))
        yield put({ type: actions.CREATE_INVESTMENTS_SUCCESS });
    } catch (error) {
        yield put({ type: actions.CREATE_INVESTMENTS_FAILURE });
        return error;
    }
}

export function* getInvestments() {
    try {
        const response = yield call(() => getDocs(collection(fbFirestore, 'investments')))
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        console.log(data)
        yield put({
            type: actions.GET_INVESTMENTS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_INVESTMENTS_FAILURE });
        return error;
    }
}

export function* getInvestmentDetails(params) {
    try {
        const response = yield call(() => getDoc(doc(fbFirestore, 'investments', `${params.investmentId}`)))
        const data = response.data()
        console.log(data)
        yield put({
            type: actions.GET_INVESTMENT_DETAILS_SUCCESS,
            payload: data,
            investmentId: params.investmentId
        });
        // const user = {currentUser: params.profileId}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
        // const user = {currentUser: 'BT2100003'}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
        yield put({ type: actions.GET_INVESTMENT_DETAILS_FAILURE });
        return error;
    }
}

// export function* getFilteredInvestors(params) {
//     try {
//         console.log(params.filter.type, params.filter.value)
//         const response = yield call(() => getDocs(collection(fbFirestore, 'investors')))
//         const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
//         console.log(data)
//         yield put({
//             type: actions.GET_INVESTORS_SUCCESS,
//             payload: data,
//         });
//     } catch (error) {
//         console.log(error)
//         yield put({ type: actions.GET_INVESTORS_FAILURE });
//         return error;
//     }
// }

export default function* mainSaga() {
    yield all([
        takeEvery(actions.CREATE_INVESTMENTS_START, createInvestments),
        takeEvery(actions.GET_INVESTMENTS_START, getInvestments),
        takeEvery(actions.GET_INVESTMENT_DETAILS_START, getInvestmentDetails),
    ]);
}
