import {IconButton} from '@mui/material';


export const PrimaryIconButton = props => {
  const {classes, color, disabled, edge, handleClose, iconComponent, label, size, ...otherProps} = props
  return (
    <IconButton
      aria-label={label}
      classes={{root: classes}}
      color={color}
      disabled={disabled}
      edge={edge}
      size={size}
      style={{color: '#FFFFFFCC'}}
      onClick={handleClose}
      {...otherProps}
    >
      {iconComponent}
    </IconButton>
  )
}
