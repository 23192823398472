import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {investorsHeader, projectsHeader} from '../../../helpers/constant';
import {Image, Space} from 'antd';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {PrimaryDrawer} from '../../Shared/Drawer/PrimaryDrawer';
import {Button, TextField} from '@mui/material';
import clsx from 'clsx';
import {useState} from 'react';
import actions from '../../../redux/main/projects/actions';
import {useDispatch} from 'react-redux';
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";


export const ProjectsTable = (props) => {
    const {projects} = props
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState(false);
    const [slug, setSlug] = useState(false);
    const [url, setUrl] = useState(false);
    const handleClose = () => {
        setVisible(false);
    };
    const onEdit = (data) => {
        setVisible(true);
        setName(data.name);
    }
    const onDelete = (data) => {
        // dispatch({
        //     type: actions.DELETE_CATEGORY_START,
        //     token: loggedInUser,
        //     id: data.name,
        // });
    }
    const dispatch = useDispatch();
    const updateCategory = () => {
        // dispatch({
        //     type: actions.UPDATE_CATEGORY_START,
        //     id: oldName,
        //     category: {
        //         name: name,
        //     },
        // });
        setVisible(false);
    }
    const loadDetails = (value) => {
        dispatch({type: actions.GET_PROJECT_DETAILS_START, projectId: value});
    };
    const classes = customStyles()
    console.log(projects)
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }}>
                    <TableHead>
                        <TableRow>
                            {projectsHeader.map(head => (
                                <TableCell>{head}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {projects.map((data) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{data.id}</TableCell>
                                <TableCell>{data.projectName}</TableCell>
                                <TableCell>{data.location}</TableCell>
                                <TableCell>{data.projectSize}</TableCell>
                                <TableCell>{data.projectValue}</TableCell>
                                <TableCell>{data.expectedReturns}</TableCell>
                                <TableCell>{data.status}</TableCell>
                                <TableCell>
                                    <Link
                                        to={`/dashboard/projects/${data.id}`}
                                        className={clsx(classes.margin8a, classes.textDecoration)}
                                    >
                                        <Button
                                            key="view"
                                            type='submit'
                                            color='primary'
                                            variant="contained"
                                            className={classes.modalBtn}
                                            onClick={() => loadDetails(data.id)}
                                        >
                                            View
                                        </Button>
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <PrimaryDrawer
                anchor={'right'}
                open={visible}
                variant={'temporary'}
                handleClose={() => handleClose()}
                title={'Update Celebrity'}
                component={
                    <>
                        <TextField
                            placeholder='Celebrity Name'
                            required
                            variant={'standard'}
                            className={clsx(classes.margin20)}
                            style={{marginTop: 40}}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            placeholder='Celebrity Slug'
                            required
                            variant={'standard'}
                            className={clsx(classes.margin20)}
                            // style={{marginTop: 40}}
                            onChange={(e) => setSlug(e.target.value)}
                        />
                        <TextField
                            placeholder='Profile URL'
                            required
                            variant={'standard'}
                            className={clsx(classes.margin20)}
                            // style={{marginTop: 40}}
                            onChange={(e) => setUrl(e.target.value)}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                padding: '10px 16px',
                                textAlign: 'left',
                                left: 0,
                                background: '#fff',
                                borderRadius: '0 0 4px 4px',
                            }}
                        >
                            <Button
                                key="yes"
                                type='submit'
                                color='primary'
                                variant="contained"
                                className={classes.modalBtn}
                                onClick={() => updateCategory()}
                            >
                                Update Investor Details
                            </Button>
                        </div>
                    </>
                }
            />
        </>
    );
}

const customStyles = makeStyles({
    margin20: {
        margin: '20px',
    },
    modalBtn: {
        minWidth: 70,
        padding: '2px 2px',
        boxShadow: 'none',
        margin: '0px 5px',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 500,

        '&:hover': {
            boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    },
})
