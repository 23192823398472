import {all, call, put, takeEvery} from 'redux-saga/effects';
import {collection, getDocs, getDoc, setDoc, doc, query, where, orderBy, limit} from "firebase/firestore";
import actions from './actions';
import {fbFirestore} from "../../../helpers/firebaseClient";

const auth = JSON.parse(window.sessionStorage.getItem('auth'));


export function* createTeam(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'members', `${'MEM' + randNum}`), params.payload))
        yield put({ type: actions.CREATE_TEAM_SUCCESS });
    } catch (error) {
        yield put({ type: actions.CREATE_TEAM_FAILURE });
        return error;
    }
}

export function* getTeam() {
    try {
        const response = yield call(() => getDocs(collection(fbFirestore, 'team')))
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_TEAM_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_TEAM_FAILURE });
        return error;
    }
}

export function* getTeamDetails(params) {
    try {
        const response = yield call(() => getDoc(doc(fbFirestore, 'team', `${params.teamId}`)))
        const data = response.data()
        yield put({
            type: actions.GET_TEAM_DETAILS_SUCCESS,
            payload: data,
            projectId: params.teamId
        });
        // const user = {currentUser: params.profileId}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
        // const user = {currentUser: 'BT2100003'}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
        yield put({ type: actions.GET_TEAM_DETAILS_FAILURE });
        return error;
    }
}

export function* getEmployees(params) {
    try {
        const response = yield call (() => getDocs(query(collection(fbFirestore, 'users'),where('role', '==', 'EMPLOYEE'))));
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_EMPLOYEES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({ type: actions.GET_EMPLOYEES_FAILURE });
        return error;
    }
}

export function* getPartners(params) {
    try {
        const response = yield call (() => getDocs(query(collection(fbFirestore, 'users'),where('role', '==', 'PARTNER'))));
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_PARTNERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({ type: actions.GET_PARTNERS_FAILURE });
        return error;
    }
}

export function* getInvestors(params) {
    try {
        const response = yield call (() => getDocs(query(collection(fbFirestore, 'users'),where('role', '==', 'INVESTOR'))));
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_INVESTORS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({ type: actions.GET_INVESTORS_FAILURE });
        return error;
    }
}

export default function* mainSaga() {
    yield all([
        takeEvery(actions.CREATE_TEAM_START, createTeam),
        takeEvery(actions.GET_TEAM_START, getTeam),
        takeEvery(actions.GET_TEAM_DETAILS_START, getTeamDetails),
        takeEvery(actions.GET_EMPLOYEES_START, getEmployees),
        takeEvery(actions.GET_PARTNERS_START, getPartners),
        takeEvery(actions.GET_INVESTORS_START, getInvestors),
    ]);
}
