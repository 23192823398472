const actions = {
    GET_CUSTOMERS_START: 'GET_CUSTOMERS_START',
    GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',
    CREATE_CUSTOMERS_START: 'CREATE_CUSTOMERS_START',
    CREATE_CUSTOMERS_SUCCESS: 'CREATE_CUSTOMERS_SUCCESS',
    CREATE_CUSTOMERS_FAILURE: 'CREATE_CUSTOMERS_FAILURE',
    DELETE_CUSTOMERS_START: 'DELETE_CUSTOMERS_START',
    DELETE_CUSTOMERS_SUCCESS: 'DELETE_CUSTOMERS_SUCCESS',
    DELETE_CUSTOMERS_FAILURE: 'DELETE_CUSTOMERS_FAILURE',
};

export default actions;
