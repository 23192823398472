const actions = {
    GET_INVESTMENTS_START: 'GET_INVESTMENTS_START',
    GET_INVESTMENTS_SUCCESS: 'GET_INVESTMENTS_SUCCESS',
    GET_INVESTMENTS_FAILURE: 'GET_INVESTMENTS_FAILURE',
    CREATE_INVESTMENTS_START: 'CREATE_INVESTMENTS_START',
    CREATE_INVESTMENTS_SUCCESS: 'CREATE_INVESTMENTS_SUCCESS',
    CREATE_INVESTMENTS_FAILURE: 'CREATE_INVESTMENTS_FAILURE',
    DELETE_INVESTMENTS_START: 'DELETE_INVESTMENTS_START',
    DELETE_INVESTMENTS_SUCCESS: 'DELETE_INVESTMENTS_SUCCESS',
    DELETE_INVESTMENTS_FAILURE: 'DELETE_INVESTMENTS_FAILURE',
    GET_INVESTMENT_DETAILS_START: 'GET_INVESTMENT_DETAILS_START',
    GET_INVESTMENT_DETAILS_SUCCESS: 'GET_INVESTMENT_DETAILS_SUCCESS',
    GET_INVESTMENTS_DETAILS_FAILURE: 'GET_INVESTMENTS_DETAILS_FAILURE',
};

export default actions;
