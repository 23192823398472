import Namakkal from '../assets/images/localities/nkl.jpg';
import Mohanur from '../assets/images/localities/monr.png';
import KabilarMalai from '../assets/images/localities/kabilarmalai.jpg';
import Velur from '../assets/images/localities/vlr.jpg';

import KMImg1 from '../assets/images/kattamarapalayam/KN - Front View.jpg';
import KMImg2 from '../assets/images/kattamarapalayam/Back Plot View.jpg';
import KMImg3 from '../assets/images/kattamarapalayam/KN 33 feet road.jpg';
import KMImg4 from '../assets/images/kattamarapalayam/KN Entrance.jpg';
import KMImg5 from '../assets/images/kattamarapalayam/Kamatchi Nagar Map.jpeg';

export const roles = [
    {
        name: 'Director',
        slug: 'director',
    },
    {
        name: 'Partner',
        slug: 'partner',
    },
    {
        name: 'Investor',
        slug: 'investor',
    },
]

export const introducers = [
    {
        name: 'Gunasekaran K',
        slug: 'guna',
    },
    {
        name: 'Mohan K',
        slug: 'mohan',
    },
    {
        name: 'Senthilkumar',
        slug: 'senthil',
    },
    {
        name: 'Kulanthaivel',
        slug: 'kv',
    },
]

export const mainMenus = {
    menu: 'Main Board',
    sub: [
        {
            menu: 'Dashboard',
            link: '/dashboard',
        },
        {
            menu: 'Projects',
            link: '/dashboard/projects',
        },
        // {
        //     menu: 'Available Fund',
        //     link: '/dashboard/funds',
        // },
        {
            menu: 'Investments',
            link: '/dashboard/investments',
        },
    ],
}

export const teamMenus = {
    menu: 'Team Members',
    sub: [
        {
            menu: 'Partners',
            link: '/dashboard/team/partners',
        },
        {
            menu: 'Investors',
            link: '/dashboard/team/investors',
        },
        {
            menu: 'Employees',
            link: '/dashboard/team/employees',
        },
    ],
}

export const otherMenus = {
    menu: 'Others',
    sub: [
        {
            menu: 'Customers',
            link: '/dashboard/customers',
        },
        {
            menu: 'Expenses',
            link: '/dashboard/expenses',
        },
        {
            menu: 'Documents',
            link: '/dashboard/documents',
        },
        // {
        //     menu: 'My Profile',
        //     link: '/dashboard/profile',
        // },
    ],
}

export const footerLeftMenus = {
    menu: 'Blog',
    sub: [
        {
            menu: 'Development Works',
            link: '/blog',
        },
    ],
}

export const footerMidMenus = {
    menu: 'Useful Links',
    sub: [
        {
            menu: 'About',
            link: '/about',
        },
        {
            menu: 'Admin Login',
            link: '/login',
        },
    ],
}

export const footerRightMenus = {
    menu: 'Other Links',
    sub: [
        {
            menu: 'Privacy Policy',
            link: '/privacy-policy',
        },
    ],
}

export const investorsHeader = ['Id', 'Name', 'Occupation', 'Investment', 'Contact', 'Place', 'Role', 'Address']

export const customersHeader = ['Customer Id', 'Customer Name', 'Looking To', 'Contact', 'Email Address']

export const investmentsHeader = ['Investor Id', 'Investor Name', 'Investment Amount', 'Plan', 'Actions']

export const projectsHeader = ['Id', 'Name', 'Location', 'Size', 'Value', 'Returns', 'Status', 'Actions']

export const teamsHeader = ['Member Id', 'Member Name', 'Introducer', 'Role', 'Actions']

export const accountsHeader = ['Data Id', 'Amount', 'From', 'To', 'Type', 'Actions']

export const indexHeader = ['Index Name', 'Symbol', 'CMP', 'Open Price', 'High', 'Low', 'Previous Close', '52 weeks High', '52 weeks Low']

export const futuresHeader = ['#', 'Company Name', 'Symbol', 'CMP', 'Prev. Close', 'High', 'Low', 'Difference', 'Max Intraday Profit', '52 weeks High', '52 weeks Low', 'Stock P/E', 'EPS']

export const futHeader = ['#', 'Company Name', 'Symbol', 'Lot Size', 'CMP', 'Total Value', 'Req. Margin', 'Prev. Close', 'High', 'Low', 'Difference', 'Max Intraday Profit', '52 weeks High', '52 weeks Low', 'Stock P/E', 'EPS', 'Action']

export const scriptsHeader = ['Company Name', 'Symbol', 'Market Capital', 'CMP', 'Open', 'Prev. Close', 'Change', 'Volume', 'Volume Average', 'High', 'Low', '52 weeks High', '52 weeks Low', 'Stock P/E', 'EPS']

export const expensesHeader = ['Id', 'Expenses', 'Amount', 'Mode', 'Given By', 'Date', 'Remarks']

export const dashboardCard = [
    {
        name: 'Total Projects',
        value: 2,
    },
    {
        name: 'Total Investments',
        value: 12500000,
    },
    {
        name: 'Total Team Members',
        value: 5,
    },
    {
        name: 'Total Cents',
        value: 68,
    },
]

export const knImages = [
    {
        url: KMImg1,
    },
    {
        url: KMImg2,
    },
    {
        url: KMImg3,
    },
    {
        url: KMImg4,
    },
    {
        url: KMImg5,
    },
]

export const developmentWorks = ['DTCP & RERA Approval', 'Compound Wall', 'Tar Road Facility', 'Street Light', 'Water Facility', 'Drainage Facility', 'Electricity Facility', 'Park Facility']

export const plImg = [
    {
        url: Velur,
        name: 'Paramathi Velur'
    },
    {
        url: Mohanur,
        name: 'Mohanur'
    },
    {
        url: Namakkal,
        name: 'Namakkal'
    },
    {
        url: KabilarMalai,
        name: 'KabilarMalai'
    },
]

export const newsPost = [
    {
        id:1,
        url: 'https://drive.google.com/thumbnail?id=1yUsGRRUzsQiPVcBOsMU4RGCkpgJ0Q7zt',
    },
    {
        id:2,
        url: 'https://drive.google.com/thumbnail?id=1f5tkboUX0uqolZR5FSKgZGZ7vVASaYOK',
    },
    {
        id:3,
        url: 'https://drive.google.com/thumbnail?id=1lq28yujU7Vf5DfKPeTs4reAny5CTirsd',
    },
    {
        id:4,
        url: 'https://drive.google.com/thumbnail?id=1lq28yujU7Vf5DfKPeTs4reAny5CTirsd',
    },
    {
        id:5,
        url: 'https://drive.google.com/thumbnail?id=1lq28yujU7Vf5DfKPeTs4reAny5CTirsd',
    },
]

