import { combineReducers } from 'redux';
import authReducer from "./auth/reducer";
import customersReducer from "./main/customers/reducer";
import investorsReducer from "./main/investors/reducer";
import investmentsReducer from "./main/investments/reducer";
import projectsReducer from "./main/projects/reducer";
import teamReducer from "./main/team/reducer";
import accountsReducer from "./main/accounts/reducer";
import expensesReducer from './main/expenses/reducer';
import projectWiseInvestmentsReducer from './main/projectWiseInvestments/reducer';
import propertiesReducer from './main/properties/reducer';
import postPropertiesReducer from './main/post-property/reducer';
import scriptsReducer from './main/scripts/reducer';

export const rootReducer = combineReducers({
    authReducer,
    customersReducer,
    investorsReducer,
    investmentsReducer,
    projectsReducer,
    teamReducer,
    accountsReducer,
    expensesReducer,
    projectWiseInvestmentsReducer,
    propertiesReducer,
    postPropertiesReducer,
    scriptsReducer,
});
