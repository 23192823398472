import {all, call, put, takeEvery} from 'redux-saga/effects';
import {collection, getDocs, getDoc, setDoc, doc, query, where, orderBy, limit} from "firebase/firestore";
import actions from './actions';
import {fbFirestore} from "../../../helpers/firebaseClient";

const auth = JSON.parse(window.sessionStorage.getItem('auth'));


export function* createCustomers(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    console.log(params)
    try {
        yield call(() => setDoc(doc(fbFirestore, 'customers', `${'CU' + randNum}`), params.payload))
        yield put({ type: actions.CREATE_CUSTOMERS_SUCCESS });
    } catch (error) {
        yield put({ type: actions.CREATE_CUSTOMERS_FAILURE });
        console.log(error)
        return error;
    }
}

export function* getCustomers() {
    try {
        const response = yield call(() => getDocs(collection(fbFirestore, 'customers')))
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_CUSTOMERS_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_CUSTOMERS_FAILURE });
        return error;
    }
}

export function* getCustomersDetails(params) {
    try {
        const response = yield call(() => getDoc(doc(fbFirestore, 'customers', `${params.customersId}`)))
        const data = response.data()
        yield put({
            type: actions.GET_CUSTOMERS_DETAILS_SUCCESS,
            payload: data,
            projectId: params.customersId
        });
        // const user = {currentUser: params.profileId}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
    } catch (error) {
        // const user = {currentUser: 'BT2100003'}
        // window.sessionStorage.setItem('user', JSON.stringify(user));
        yield put({ type: actions.GET_CUSTOMERS_DETAILS_FAILURE });
        return error;
    }
}

// export function* getFilteredInvestors(params) {
//     try {
//         console.log(params.filter.type, params.filter.value)
//         const response = yield call(() => getDocs(collection(fbFirestore, 'investors')))
//         const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
//         console.log(data)
//         yield put({
//             type: actions.GET_INVESTORS_SUCCESS,
//             payload: data,
//         });
//     } catch (error) {
//         console.log(error)
//         yield put({ type: actions.GET_INVESTORS_FAILURE });
//         return error;
//     }
// }

export default function* mainSaga() {
    yield all([
        takeEvery(actions.CREATE_CUSTOMERS_START, createCustomers),
        takeEvery(actions.GET_CUSTOMERS_START, getCustomers),
        // takeEvery(actions.GET_CUSTOMERS_DETAILS_START, getcustomersDetails),
    ]);
}
