import {all, call, put, takeEvery} from 'redux-saga/effects';
import actions from './actions';
import {history} from "../../redux/store";

import {fbFirestore, firebaseInit} from "../../helpers/firebaseClient";

import {getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword} from "firebase/auth"
import {query, where, getDocs, setDoc, doc, collection} from "firebase/firestore";

import { toast } from "react-toastify";

const authInit = getAuth();
const auth = JSON.parse(window.sessionStorage.getItem('auth'));

export function* login(params) {
    const email = params.loginData.email
    const password = params.loginData.password
    try {
        console.log(params)
        const response = yield call(() => signInWithEmailAndPassword(authInit, email, password));
        const authDetails = yield call (() => getDocs(query(collection(fbFirestore, 'auth'),where('authId', '==', `${response.user.uid}`))))
        console.log(authDetails.docs.map((doc) => ({...doc.data()})))
        yield put({
            type: actions.LOGIN_SUCCESS,
            // payload: response.user,
            // authDetails: authDetails.docs.map((doc) => ({...doc.data(), userId: doc.id}))
        });
        const auth = {
            token: response.user.uid,
            isUserLoggedIn: true,
        }
        window.sessionStorage.setItem('auth', JSON.stringify(auth));
        window.location.reload(false);
    } catch (error) {
        yield put({ type: actions.LOGIN_FAILURE });
        const auth = {
            token: '',
            isUserLoggedIn: false,
        }
        window.sessionStorage.setItem('auth', JSON.stringify(auth));
        return toast(error);
    }
}

export function* customerLogin(params) {
    const email = params.loginData.email
    const password = params.loginData.password
    try {
        const response = yield call(() => signInWithEmailAndPassword(authInit, email, password));
        const authDetails = yield call (() => getDocs(query(collection(fbFirestore, 'customers'),where('authId', '==', `${response.user.uid}`))))
        yield put({
            type: actions.LOGIN_SUCCESS,
            // payload: response.user,
            // authDetails: authDetails.docs.map((doc) => ({...doc.data(), userId: doc.id}))
        });
        const auth = {
            token: response.user.uid,
            isUserLoggedIn: true,
        }
        window.sessionStorage.setItem('auth', JSON.stringify(auth));
        window.location.reload(false);
    } catch (error) {
        yield put({ type: actions.LOGIN_FAILURE });
        const auth = {
            token: '',
            isUserLoggedIn: false,
        }
        window.sessionStorage.setItem('auth', JSON.stringify(auth));
        return toast(error);
    }
}

export function* customerRegistration(params) {
    const name = params.registerData.name
    const email = params.registerData.email
    const password = params.registerData.password
    const phone = params.registerData.phone
    const lookingTo = params.registerData.lookingTo
    const type = params.registerData.type
    const userId = params.registerData.userId
    try {
        const registerAuth = yield call(() => createUserWithEmailAndPassword(authInit, email, password));
        const registerDetails = {
            name: name,
            email: email,
            password: password,
            phone: phone,
            userId: userId,
            lookingTo: lookingTo,
            type: type,
            authId: registerAuth.user.uid
        }
        const response = yield call(() => setDoc(doc(fbFirestore, 'customers', `${registerAuth.user.uid}`), registerDetails))
        yield put({
            type: actions.REGISTER_SUCCESS,
            payload: response,
        });
    } catch (error) {
        yield put({ type: actions.REGISTER_FAILURE });
        return error;
    }
}

export function* logout() {
    try {
        // const response = yield call(() => firebaseInit.signOut());
        window.sessionStorage.removeItem('auth');
        history.push('/')
        window.location.reload(false);
        yield put({
            type: actions.LOGOUT_SUCCESS,
            // payload: response.data,
        });
    } catch (error) {
        yield put({ type: actions.LOGOUT_FAILURE });
        return error;
    }
}

export default function* authSaga() {
    yield all([
        takeEvery(actions.LOGIN_START, login),
        takeEvery(actions.CUSTOMER_REGISTRATION_START, customerRegistration),
        takeEvery(actions.CUSTOMER_LOGIN_START, customerLogin),
        takeEvery(actions.LOGOUT_START, logout),
    ]);
}
