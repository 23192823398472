import {all, call, put, takeEvery} from 'redux-saga/effects';
import actions from './actions';
import axios from 'axios';
// import SmartApi from 'smartapi-javascript/lib/smartapi-connect';
import { KiteConnect } from 'kiteconnect';

const auth = JSON.parse(window.sessionStorage.getItem('auth'));

const kiteConnect = new KiteConnect({
    apiKey: 'ssxk9WhK'
})

// const smartApi = new SmartApi({
//     apiKey: 'ssxk9WhK'
// })

export function* getScripts() {
    try {
        const response = yield call(axios.get, 'https://script.google.com/macros/s/AKfycbyTHrSjQx9fM9uI2PdcfYkFnpPDROn8giUrsr9biYl6-Z-dmJnx4klDW0wG_HoWWGkV/exec', {mode: 'cors'})
        console.log(response)
        yield put({
            type: actions.GET_SCRIPTS_SUCCESS,
            payload: response.data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_SCRIPTS_FAILURE });
        return error;
    }
}

// export function* getIndices() {
//     try {
//         const response = yield call(axios.get, 'https://script.google.com/macros/s/AKfycbzo-cD2pXw2_E_vE7yxhw_cq3fV0xryxGjlVV6O1PvY2szXZXcyO4Y3G5hfHn_fbRrp/exec')
//         console.log(response)
//         yield put({
//             type: actions.GET_INDICES_SUCCESS,
//             payload: response.data,
//         });
//     } catch (error) {
//         console.log(error)
//         yield put({ type: actions.GET_INDICES_FAILURE });
//         return error;
//     }
// }

// export function* getScripts() {
//     try {
//         const response = yield call(smartApi.generateSession, 'R1057877', '0852', '782492');
//         console.log(response.getProfile())
//         yield put({
//             type: actions.GET_SCRIPTS_SUCCESS,
//             payload: response.data,
//         });
//     } catch (error) {
//         console.log(error)
//         yield put({ type: actions.GET_SCRIPTS_FAILURE });
//         return error;
//     }
// }

export default function* mainSaga() {
    yield all([
        takeEvery(actions.GET_SCRIPTS_START, getScripts),
        // takeEvery(actions.GET_INDICES_START, getIndices),
    ]);
}
