import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Button, Grid, Paper, Typography} from "@mui/material";
import clsx from "clsx";
import {Link} from "react-router-dom";
import {makeStyles} from '@mui/styles';
import actions from '../redux/main/scripts/actions';
import Navbar from './Header/Navbar';
import _ from 'lodash';

import {InstagramEmbed} from 'react-social-media-embed';
import {YouTubeEmbed} from 'react-social-media-embed';

import ImageGallery from 'react-image-gallery';
import {Image} from "antd";
import 'antd/dist/antd.css';
import {newsPost} from '../helpers/constant';
import Footer from './Footer';


const WelcomePage = () => {
    const {scripts, indices} = useSelector(state => state.scriptsReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({
            type: actions.GET_SCRIPTS_START,
            filter: {
                type: 'role',
                value: 'Director'
            }
        });
    }
    const classes = customStyles();
    console.log(newsPost)
    return (
        <>
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <div style={{backgroundColor: "#FEF7EA", padding: 5, margin: 20, borderRadius: 30}}>
                <div style={{color: "#090909", textAlign: "center", fontSize: '20px', fontWeight: 400}}>
                    <span style={{color: "#0C5AA5", fontWeight: 600}}>"Money is a terrible master but an excellent servant!"</span> is now trending...
                    <Link to={'/courses'} style={{color: '#090909'}}>
                        <Button disableRipple className={classes.postBtn} style={{margin: '10px 0'}}>
                            Enroll Now
                        </Button>
                    </Link>
                </div>
            </div>

            <div style={{backgroundColor: "#F1F4F9", padding: 20, margin: 20, borderRadius: 50}}>
                <div style={{color: "#090909", textAlign: "center", fontSize: '20px', fontWeight: 600, padding: 20}}>
                    <Typography variant="h4" style={{color: "#000", fontWeight: 600, fontSize: '28px'}}>You are the root of your financial success or failure. <br />If you work on the roots, the 'fruits' will take care of themselves</Typography>
                </div>
                <div style={{color: "#090909", textAlign: "center", fontSize: '20px', fontWeight: 600, padding: 10}}>
                    <Link to={'/courses'} style={{color: '#090909'}}>
                        <Button disableRipple className={classes.postBtn}>
                            Partnership
                        </Button>
                    </Link>
                    <Link to={'/courses'} style={{color: '#090909'}}>
                        <Button disableRipple className={classes.postBtn}>
                            Mentorship
                        </Button>
                    </Link>
                </div>
            </div>

            {/* <Grid container className={classes.flexCenter}>
                <Grid item style={{padding: 20}}>
                    <Typography variant="h4" className={classes.cardTitle}>Celebrity Images</Typography>
                    <Grid container>
                        {celebrityImages.map((item) => (
                            <Grid item sm={6} md={4} lg={2.4} style={{width: '100%'}}>
                                <div className={clsx(classes.profileCardStyle, classes.margin8a)} key={item.id}>
                                    <div>
                                        <ImageGallery
                                            items={item.images.map(src => {return {original: `${src.url}`, thumbnail: `${src.url}`}})}
                                            autoPlay={false}
                                            showBullets={true}
                                            showNav={false}
                                            showThumbnails={false}
                                            showFullscreenButton={false}
                                            showPlayButton={false}
                                        />
                                    </div>
                                    <Typography variant="h6" style={{backgroundColor: '#202234', color: '#FFF', padding: 10, textAlign: 'center'}}>{item.title}</Typography>
                                </div>
                            </Grid>
                        ))}
                    </Grid>

                    <Typography variant="h4" className={classes.cardTitle}>Celebrity Clips</Typography>
                    <Grid container>
                        {celebrityClips.map((item) => (
                            <Grid item sm={6} md={4} lg={2.4} style={{width: '100%'}}>
                                <div className={clsx(classes.profileCardStyle, classes.margin8a)} key={item.id}>
                                    <CardMedia
                                        component="video"
                                        src={`${item.clip}`}
                                        alt={item.title}
                                        controls
                                        controlsList="nofullscreen nodownload noplaybackrate"
                                    />
                                    <Typography variant="h6" style={{backgroundColor: '#202234', color: '#FFF', padding: 10, textAlign: 'center'}}>{item.title}</Typography>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                    <Typography variant="h4" className={classes.cardTitle}>Trending Celebrities</Typography>
                    <Grid container className={classes.flexCenter}>
                        {celebrities.map((item) => (
                            <Grid item sm={6} md={4} lg={2.4}>
                                <div className={clsx(classes.profileCardStyle, classes.margin8a)} key={item.id}>
                                    <Image
                                        alt={item.title}
                                        preview={false}
                                        src={`${item.profileImage}`}
                                    />
                                    <Typography variant="h6" style={{backgroundColor: '#202234', color: '#FFF', padding: 10, textAlign: 'center'}}>{item.name}</Typography>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid> */}

            <Grid style={{padding: 20}}>
                <Typography variant="h4" style={{color: "#0c5aa5", padding: "1.5rem", textAlign: "left", fontWeight: 600}}>News Feeds</Typography>
                <Grid container>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <InstagramEmbed url="https://www.instagram.com/p/DDgqIx3vWdG/" />    
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <InstagramEmbed url="https://www.instagram.com/p/DDgqIx3vWdG/" />    
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <InstagramEmbed url="https://www.instagram.com/p/DDgqIx3vWdG/" />    
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{padding: 20}}>
                <Typography variant="h4" style={{color: "#0c5aa5", padding: "1.5rem", textAlign: "left", fontWeight: 600}}>Video Content</Typography>
                <Grid container>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <YouTubeEmbed url="https://www.youtube.com/watch?v=HpVOs5imUN0" width={325} height={220} />   
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <YouTubeEmbed url="https://www.youtube.com/watch?v=HpVOs5imUN0" width={325} height={220} />  
                        </div>
                    </Grid>
                    <Grid item sm={6} md={4} lg={3} style={{width: '100%'}}>
                        <div style={{padding: 20}}>
                            <YouTubeEmbed url="https://www.youtube.com/watch?v=HpVOs5imUN0" width={325} height={220} />  
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid style={{padding: 16}}>
                <div>
                    <Typography variant="h4" style={{color: "#0c5aa5", paddingLeft: "1.5rem", textAlign: "left", fontWeight: 600}}>Ideas for getting started</Typography>
                    <Typography style={{color: "#000", paddingLeft: "1.5rem", textAlign: "left", fontSize: '16px', fontWeight: 400}}>Chase the vision not money. The money will end up following you.</Typography>
                </div>
                <Grid container>
                    {scripts.map((data) => (
                        <Grid item xs={12} sm={6} md={4} lg={3} key={data.companyName}>
                            <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                                <Link to={`/company/${data.symbol.slice(4, data.symbol.length)}`} className={clsx(classes.margin8a, classes.textDecoration)}>
                                    <Typography variant="h6" style={{fontWeight: 600, fontSize: '18px'}}>
                                        {data.companyName}
                                    </Typography>
                                </Link>
                                <Typography variant="h6" style={{fontWeight: 600, color: '#7F90A2', fontSize: '14px'}}>
                                    {data.symbol.slice(4, data.symbol.length)}
                                </Typography>
                                <hr />
                                <Grid container >
                                    <Grid item xs={12} md={6} lg={6} style={{padding: 10}}>
                                        <Typography variant="subtitle" align="center" className={classes.padding8}>
                                            Lot Size
                                        </Typography>
                                        <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                            {data.lotSize}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} style={{padding: 10}}>
                                        <Typography variant="subtitle" align="center" className={classes.padding8}>
                                            CMP
                                        </Typography>
                                        <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                            {data.cmp}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} style={{padding: 10}}>
                                        <Typography variant="subtitle" align="center" className={classes.padding8}>
                                            Today's High
                                        </Typography>
                                        <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                            {data.high}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6} lg={6} style={{padding: 10}}>
                                        <Typography variant="subtitle" align="center" className={classes.padding8}>
                                            Today's Low
                                        </Typography>
                                        <Typography variant="h6" className={classes.padding8} style={{fontWeight: 600, fontSize: '16px'}}>
                                            {data.low}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={8} lg={8} style={{padding: 10}}>
                                        <Typography variant="subtitle" align="center" className={classes.padding8}>
                                            Max Intraday Profit
                                        </Typography>
                                        <Typography variant="h6" className={classes.padding8} style={{color: '#34A853', fontWeight: 600}}>
                                            {_.round((data.maxIntradayProfit), 2)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Footer />
        </>
    );
};

export default WelcomePage;

const customStyles = makeStyles({
    flexCenter: {
        display: "flex",
        justifyContent: "center",
        padding: "2rem",
    },
    padding8: {
        padding: 8,
    },
    postBtn: {
        minWidth: 120,
        margin: '10px 10px',
        padding: '8px 20px',
        boxShadow: 'none',
        borderRadius: 20,
        color: '#FFF',
        fontWeight: 600,
        backgroundColor: '#00B050',

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#EBF5F0',
            color: '#00B050',
        },
    },
})
