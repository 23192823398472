import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {scriptsHeader} from '../../helpers/constant';
import {Button, TextField} from '@mui/material';
import clsx from 'clsx';
import {useState} from 'react';
import actions from '../../redux/main/scripts/actions';
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import _ from 'lodash';
import {indexHeader} from '../../helpers/constant';


export const NiftyMidcapTable = (props) => {
    const {niftyMidcap} = props
    const [visible, setVisible] = useState(false);
    const handleClose = () => {
        setVisible(false);
    };
    const dispatch = useDispatch();
    const loadDetails = (value) => {
        dispatch({type: actions.GET_SCRIPT_DETAILS_START, symbol: value});
    };
    const numberFormat = (value) => {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const classes = customStyles();
    return (
        <>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 400 }}>
                    <TableHead>
                        <TableRow>
                            {scriptsHeader.map(head => (
                                <TableCell sx={{fontWeight: 600}}>{head}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {niftyMidcap.map((data) => (
                            <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>
                                    <Link
                                        to={`/discover/${data.symbol}`}
                                        className={clsx(classes.margin8a, classes.textDecoration)}
                                    >
                                        {data.companyName}
                                    </Link>
                                </TableCell>
                                <TableCell>{data.symbol}</TableCell>
                                <TableCell>{'₹' + numberFormat(data.marketCap)}</TableCell>
                                <TableCell>{'₹' + numberFormat(data.cmp)}</TableCell>
                                <TableCell>{'₹' + numberFormat(data.open)}</TableCell>
                                <TableCell>{'₹' + numberFormat(data.prevClose)}</TableCell>
                                <TableCell>{data.change}</TableCell>
                                <TableCell>{numberFormat(data.volume)}</TableCell>
                                <TableCell>{numberFormat(data.volumeAvg)}</TableCell>
                                <TableCell>{'₹' + numberFormat(data.high)}</TableCell>
                                <TableCell>{'₹' + numberFormat(data.low)}</TableCell>
                                <TableCell>{'₹' + numberFormat(data.l52wHigh)}</TableCell>
                                <TableCell>{'₹' + numberFormat(data.l52wLow)}</TableCell>
                                <TableCell>{data.stockPE}</TableCell>
                                <TableCell>{data.eps}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

const customStyles = makeStyles({
    margin20: {
        margin: '20px',
    },
    modalBtn: {
        minWidth: 70,
        padding: '2px 2px',
        boxShadow: 'none',
        margin: '0px 5px',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 500,

        '&:hover': {
            boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    },
})
