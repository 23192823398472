import {AppBar, Toolbar} from '@mui/material';

export const PrimaryNavbar = props => {
  const {appbarClasses, color, component, disableGutters, position, toolbarClasses, variant, ...otherProps} = props
  return (
    <AppBar
      classes={{root: appbarClasses}}
      color={color || 'inherit'}
      position={position || 'static'}
      style={{backgroundColor: '#151B30'}}
    >
      <Toolbar
        classes={{root: toolbarClasses}}
        disableGutters={disableGutters || false}
        variant={variant || 'regular'}
        {...otherProps}
      >
        {component}
      </Toolbar>
    </AppBar>
  )
}
