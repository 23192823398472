import React, {useEffect, useState} from 'react'
import {Grid, TextField, Typography, InputLabel, MenuItem, FormControl, Select} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux';
import actions from '../../../redux/main/team/actions';
import AuthNavbar from '../../Shared/Header/AuthNavbar';
import {Link} from 'react-router-dom';
import {makeStyles} from '@mui/styles';
import {Breadcrumb} from 'antd';
import PrimaryCard from '../../Shared/Card/PrimaryCard';
import Button from '@mui/material/Button';
import {EmployeesTable} from './EmployeesTable';
import {PrimaryDrawer} from '../../Shared/Drawer/PrimaryDrawer';
import DashboardDrawer from '../drawer';
import {introducers} from '../../../helpers/constant';
import clsx from 'clsx';
import {serverTimestamp} from 'firebase/firestore';

const Employees = () => {
    const {employees} = useSelector(state => state.teamReducer);
    const [visible, setVisible] = useState(false);
    const [name, setName] = useState(false);
    const [place, setPlace] = useState(false);
    const [occupation, setOccupation] = useState(false);
    const [dateOfJoining, setDateOfJoining] = useState(false);
    const [role, setRole] = useState(false);
    const [introducer, setIntroducer] = useState(false);
    const [contact, setContact] = useState(false);
    const [address, setAddress] = useState(false);
    const handleOpen = () =>{
        setVisible(true);
    };
    const handleClose = () => {
        setVisible(false);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({type: actions.GET_EMPLOYEES_START});
    }
    const addInvestors = () => {
        dispatch({
            type: actions.CREATE_EMPLOYEES_START,
            payload: {
                name: name,
                place: place,
                occupation: occupation,
                dateOfJoining: dateOfJoining,
                contact: contact,
                role: role,
                introducer: introducer,
                address: address,
                createdAt: serverTimestamp()
            }
        });
        setVisible(false);
        dispatch({type: actions.GET_EMPLOYEES_START});
    }
    const classes = customStyles();
    return(
        <>
            <AuthNavbar value={'Employees'} />
            <div>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={3} md={3} sm={12} style={{width: '100%', backgroundColor: '#F7F7FF'}}>
                        <DashboardDrawer page={'Employees'} />
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} style={{padding: 20}}>
                        <Breadcrumb style={{
                            marginLeft: 20,
                            color: '#26d48c',
                            fontSize: '18px'
                        }}>
                            <Breadcrumb.Item><Link to={'/dashboard'}> Dashboard </Link></Breadcrumb.Item>
                            <Breadcrumb.Item> Employees </Breadcrumb.Item>
                        </Breadcrumb>
                        <div className={classes.margin20}>
                            <PrimaryCard
                                title={'Employees'}
                                component={
                                    <>
                                        <Button
                                            variant={'contained'}
                                            style={{
                                                marginBottom: 20,
                                                textTransform: 'capitalize',
                                                color: '#FFFFFF'
                                            }}
                                            onClick={() => handleOpen()}
                                        >
                                            Add Employee
                                        </Button>
                                        <EmployeesTable employees={employees} />
                                    </>
                                }
                            />
                            
                            <PrimaryDrawer
                                anchor={'right'}
                                open={visible}
                                variant={'temporary'}
                                handleClose={() => handleClose()}
                                title={'Add Employee'}
                                component={
                                    <>
                                        <TextField
                                            placeholder='Name'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            style={{marginTop: 40}}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Contact'
                                            type='number'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setContact(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Place'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setPlace(e.target.value)}
                                        />
                                        <FormControl className={classes.margin20a}>
                                            <InputLabel id="role">Role</InputLabel>
                                            <Select
                                                id="role"
                                                value={role}
                                                label="Role"
                                                onChange={(e) => setRole(e.target.value)}
                                            >
                                                <MenuItem value={"Director"}>Director</MenuItem>
                                                <MenuItem value={"Partner"}>Partner</MenuItem>
                                                <MenuItem value={"Investor"}>Investor</MenuItem>
                                                <MenuItem value={"Project Adviser"}>Project Adviser</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <FormControl className={classes.margin20a}>
                                            <InputLabel id="introducer">Introducer</InputLabel>
                                            <Select
                                                id="introducer"
                                                value={introducer}
                                                label="introducer"
                                                onChange={(e) => setIntroducer(e.target.value)}
                                            >
                                                {introducers.map(person => (
                                                    <MenuItem value={person.slug}>{person.name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            placeholder='Occupation'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setOccupation(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Date of Joining'
                                            type='date'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setDateOfJoining(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Address'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setAddress(e.target.value)}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                width: '100%',
                                                padding: '10px 16px',
                                                textAlign: 'left',
                                                left: 0,
                                                background: '#fff',
                                                borderRadius: '0 0 4px 4px',
                                            }}
                                        >
                                            <Button
                                                key='yes'
                                                type='submit'
                                                color='primary'
                                                variant='contained'
                                                className={classes.modalBtn}
                                                onClick={() => addInvestors()}
                                            >
                                                Add Investor
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Employees

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin20a: {
        margin: '10px 20px',
    },
    margin40: {
        margin: '40px',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
