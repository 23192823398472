import React, {useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import {Button, Grid, Typography} from '@mui/material';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {Breadcrumb} from "antd";
import actions from '../../../redux/main/investments/actions';
import AuthNavbar from '../../Shared/Header/AuthNavbar';
import DashboardDrawer from '../drawer';


const InvestmentDetails = (props) => {
    const {investment} = props
    const investmentId = investment.match.params.investmentId
    const {investmentDetails} = useSelector(state => state.investmentsReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({type: actions.GET_INVESTMENT_DETAILS_START, investmentId: investmentId});
    };
    const classes = customStyles()
    return(
        <>
            <AuthNavbar value={'Investment Details'} />
            <div>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={3} md={3} sm={12} style={{width: '100%', backgroundColor: '#F7F7FF'}}>
                        <DashboardDrawer page={'Investments'} />
                    </Grid>
                    <Grid item lg={9} md={8} sm={12} style={{padding: 20}}>
                        <Breadcrumb style={{
                            marginLeft: 20,
                            color: '#151B30',
                            fontSize: '18px'
                        }}>
                            <Breadcrumb.Item><Link to={'/dashboard'}> Dashboard </Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={'/dashboard/investments'}> Investments </Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{investmentId}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div style={{margin: 10, padding: 10}}>
                            <Typography variant="h5" style={{fontSize: '20px', fontWeight: 500, padding: '10px', backgroundColor: '#F0F8FF'}}>{'Investment Details'}</Typography>
                            <Typography variant="h5" style={{fontSize: '24px', fontWeight: 600, padding: '10px 0', color: '#1234EF'}}>{investmentDetails.investorName}</Typography>
                            <Typography variant="h6">Contact : <span className={classes.primaryTypo}>{investmentDetails.investorId}</span></Typography>
                            <Typography variant="h6">Invested Amount : <span className={classes.primaryTypo}>{investmentDetails.totalInvestment}</span></Typography>
                            <Typography variant="h6">Plan : <span className={classes.primaryTypo}>{investmentDetails.plan}</span></Typography>
                            <Typography variant="h6" style={{padding: '20px 0 0 0', fontWeight: 500, color: '#1890ff'}}>Date-wise Investment</Typography>
                            {investmentDetails.investedAmount.map(value =>{ 
                                return (
                                <div style={{margin: 10, padding: 10}}>
                                    <Typography variant="h6">Invested Date : <span className={classes.primaryTypo}>{!investmentDetails ? new Date() : (value.date === undefined ? new Date() : new Date(value.date.toDate()).toLocaleDateString())}</span></Typography>
                                    <Typography variant="h6">Invested Amount : <span className={classes.primaryTypo}>{value.amount}</span></Typography>
                                </div>
                            )})}
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default InvestmentDetails

const customStyles = makeStyles({
    margin20: {
        margin: '20px',
    },
    modalBtn: {
        minWidth: 70,
        padding: '2px 2px',
        boxShadow: 'none',
        margin: '0px 5px',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 500,

        '&:hover': {
            boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    },
    primaryTypo: {
        padding: '8px 10px',
        fontWeight: 600
    },
})
