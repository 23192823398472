import React, {useEffect} from 'react'
import {makeStyles} from "@mui/styles";
import {Button, Grid, Typography} from '@mui/material'
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import {Breadcrumb} from "antd";
import actions from '../../../redux/main/investors/actions';
import AuthNavbar from '../../Shared/Header/AuthNavbar';
import {mainMenus} from "../../../helpers/constant";


const InvestorDetails = (props) => {
    const {investor} = props
    const investorId = investor.match.params.investorId
    const {investorDetails} = useSelector(state => state.investorsReducer);
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({type: actions.GET_INVESTOR_DETAILS_START, investorId: investorId});
    };
    const classes = customStyles()
    return(
        <>
            <AuthNavbar value={'Investor Details'} />
            <div>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={3} md={3} sm={12} style={{width: '100%', backgroundColor: '#F7F7FF'}}>
                        <div style={{padding: 10}}>
                            <Typography style={{fontSize: '18px', margin: '0.5em', color: '#6A6A6A'}}>{mainMenus.menu}</Typography>
                            <div style={{paddingLeft: 10}}>
                                {mainMenus.sub.map(menus => (
                                    <Link to={menus.link}><Typography style={{fontSize: '16px', color: '#232323', padding: 5}}>{menus.menu} </Typography></Link>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item lg={9} md={8} sm={12} style={{padding: 20}}>
                        <Breadcrumb style={{
                            marginLeft: 20,
                            color: '#151B30',
                            fontSize: '18px'
                        }}>
                            <Breadcrumb.Item><Link to={'/dashboard'}> Dashboard </Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={'/dashboard/investors'}> Investors </Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{investorId}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div style={{margin: 10, padding: 10}}>
                            <Typography variant="h5" style={{fontSize: '20px', fontWeight: 500, padding: '10px', backgroundColor: '#F0F8FF'}}>{'Investor Details'}</Typography>
                            <Typography variant="h5" style={{fontSize: '24px', fontWeight: 600, padding: '10px 0', color: '#1842b6'}}>{investorDetails.name}</Typography>
                            <Typography variant="h6">Place : <span className={classes.primaryTypo}>{investorDetails.place}</span></Typography>
                            <Typography variant="h6">Occupation : <span className={classes.primaryTypo}>{investorDetails.occupation}</span></Typography>
                            <Typography variant="h6">Role : <span className={classes.primaryTypo}>{investorDetails.role}</span></Typography>
                            <Typography variant="h6">Contact : <span className={classes.primaryTypo}>{investorDetails.contact}</span></Typography>
                            <Typography variant="h6">Address : <span className={classes.primaryTypo}>{investorDetails.address}</span></Typography>
                            <Typography variant="h6">Introducer : <span className={classes.primaryTypo}>{investorDetails.introducer}</span></Typography>
                            <Typography variant="h6">Investment :  <span className={classes.primaryTypo}>{investorDetails.investment}</span></Typography>
                            <Typography variant="h6">NetWorth : <span className={classes.primaryTypo}>{investorDetails.networth}</span></Typography>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default InvestorDetails

const customStyles = makeStyles({
    margin20: {
        margin: '20px',
    },
    modalBtn: {
        minWidth: 70,
        padding: '2px 2px',
        boxShadow: 'none',
        margin: '0px 5px',
        borderRadius: 10,
        textTransform: 'capitalize',
        fontWeight: 500,

        '&:hover': {
            boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    },
    primaryTypo: {
        padding: '8px 10px',
        fontWeight: 600
    },
})
