import * as React from 'react';
import Typography from '@mui/material/Typography';
import {Link} from 'react-router-dom';
import {makeStyles} from "@mui/styles";
import {mainMenus, otherMenus, teamMenus} from '../../helpers/constant';


const DashboardDrawer = ({page}) => {
    console.log(page)
    const classes = customStyles();
    return (
        <div style={{padding: 10}}>
            <Typography style={{fontSize: '14px', margin: '0.5em', color: '#6A6A6A'}}>{mainMenus.menu}</Typography>
            <div style={{paddingLeft: 10}}>
                {mainMenus.sub.map(menus => (
                    <Link to={menus.link}><Typography className={classes.text} style={page===menus.menu ? {color: '#0818A8', fontWeight: 600} : {}}>{menus.menu}</Typography></Link>
                ))}
            </div>

            <Typography style={{fontSize: '14px', margin: '0.5em', color: '#6A6A6A'}}>{teamMenus.menu}</Typography>
            <div style={{paddingLeft: 10}}>
                {teamMenus.sub.map(menus => (
                    <Link to={menus.link}><Typography className={classes.text} style={page===menus.menu ? {color: '#0818A8', fontWeight: 600} : {}}>{menus.menu}</Typography></Link>
                ))}
            </div>

            <Typography style={{fontSize: '14px', margin: '0.5em', color: '#6A6A6A'}}>{otherMenus.menu}</Typography>
            <div style={{paddingLeft: 10}}>
                {otherMenus.sub.map(menus => (
                    <Link to={menus.link}><Typography className={classes.text} style={page===menus.menu ? {color: '#0818A8', fontWeight: 600} : {}}>{menus.menu}</Typography></Link>
                ))}
            </div>
        </div>
    );
}

export default DashboardDrawer

const customStyles = makeStyles({
    text: {
        fontSize: '16px', 
        color: '#232323', 
        padding: 5,
        fontWeight: 600
    },
})
