import React from "react";
import {makeStyles} from "@mui/styles";
import {List, ListItem, ListItemText, Drawer, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import clsx from "clsx";


const DrawerComponent = ({open, toggleDrawerHandler}) => {
    const classes = customStyles();
    const menus = [
        {
            link: "/",
            name: "Home",
            variant: "default",
            button: false,
        },
        {
            link: "/discover",
            name: "Discover",
            variant: "default",
            button: false,
        },
        {
            link: "/customers/login",
            name: "Login",
            variant: "default",
            button: false,
        },
        {
            link: "/customers/registration",
            name: "Join the Club",
            variant: "default",
            button: true,
        },
    ];
    return (
        <Drawer open={open} onClose={toggleDrawerHandler}>
            <div
                style={{width: 250}}
                role="presentation"
                onClick={toggleDrawerHandler}
                onKeyDown={toggleDrawerHandler}
            >
                <Typography variant='h5' style={{padding: '10px 16px 10px 16px'}}>
                    <span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>MATRIX</span>
                </Typography> 
                <hr style={{margin: 0}} />
                <List>
                    {menus.map((value) => (
                        <ListItem button key={value.name}>
                            <Link to={value.link} className={clsx(classes.textDecoration)} style={{color: 'rgb(32, 34, 52)'}}><ListItemText primary={value.name} /></Link>
                        </ListItem>
                    ))}
                </List>
            </div>
        </Drawer>
    );
}

export default DrawerComponent;

const customStyles = makeStyles({
    textDecoration: {
        textDecoration: 'none'
    }
})
