import actions from './actions';

const initState = {
    loader: false,
    customers: [],
    customersDetails: [],
};

export default function mainReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_CUSTOMERS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_CUSTOMERS_SUCCESS: {
            return {
                ...state,
                loader: false,
                customers: action.payload,
            };
        }
        case actions.GET_CUSTOMERS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_CUSTOMERS_DETAILS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_CUSTOMERS_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                customersDetails: action.payload,
            };
        }
        case actions.GET_CUSTOMERS_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
