import actions from './actions';

const initState = {
    loader: false,
    projects: [],
    projectDetails: [],
    projectExpenses: [],
    projectAccounts: [],
};

export default function mainReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PROJECTS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_PROJECTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                projects: action.payload,
            };
        }
        case actions.GET_PROJECTS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_PROJECT_DETAILS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_PROJECT_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                projectDetails: action.payload,
            };
        }
        case actions.GET_PROJECT_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_PROJECT_EXPENSES_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_PROJECT_EXPENSES_SUCCESS: {
            return {
                ...state,
                loader: false,
                projectExpenses: action.payload,
            };
        }
        case actions.GET_PROJECT_EXPENSES_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_PROJECT_ACCOUNTS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_PROJECT_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                projectAccounts: action.payload,
            };
        }
        case actions.GET_PROJECT_ACCOUNTS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
