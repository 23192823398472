const actions = {
    GET_PROJECTS_START: 'GET_PROJECTS_START',
    GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',
    CREATE_PROJECTS_START: 'CREATE_PROJECTS_START',
    CREATE_PROJECTS_SUCCESS: 'CREATE_PROJECTS_SUCCESS',
    CREATE_PROJECTS_FAILURE: 'CREATE_PROJECTS_FAILURE',
    DELETE_PROJECTS_START: 'DELETE_PROJECTS_START',
    DELETE_PROJECTS_SUCCESS: 'DELETE_PROJECTS_SUCCESS',
    DELETE_PROJECTS_FAILURE: 'DELETE_PROJECTS_FAILURE',
    GET_PROJECT_DETAILS_START: 'GET_PROJECT_DETAILS_START',
    GET_PROJECT_DETAILS_SUCCESS: 'GET_PROJECT_DETAILS_SUCCESS',
    GET_PROJECT_DETAILS_FAILURE: 'GET_PROJECT_DETAILS_FAILURE',

    GET_PROJECT_EXPENSES_START: 'GET_PROJECT_EXPENSES_START',
    GET_PROJECT_EXPENSES_SUCCESS: 'GET_PROJECT_EXPENSES_SUCCESS',
    GET_PROJECT_EXPENSES_FAILURE: 'GET_PROJECT_EXPENSES_FAILURE',
    CREATE_PROJECT_EXPENSE_START: 'CREATE_PROJECT_EXPENSE_START',
    CREATE_PROJECT_EXPENSE_SUCCESS: 'CREATE_PROJECT_EXPENSE_SUCCESS',
    CREATE_PROJECT_EXPENSE_FAILURE: 'CREATE_PROJECT_EXPENSE_FAILURE',
    DELETE_PROJECT_EXPENSE_START: 'DELETE_PROJECT_EXPENSE_START',
    DELETE_PROJECT_EXPENSE_SUCCESS: 'DELETE_PROJECT_EXPENSE_SUCCESS',
    DELETE_PROJECT_EXPENSE_FAILURE: 'DELETE_PROJECT_EXPENSE_FAILURE',

    GET_PROJECT_ACCOUNTS_START: 'GET_PROJECT_ACCOUNTS_START',
    GET_PROJECT_ACCOUNTS_SUCCESS: 'GET_PROJECT_ACCOUNTS_SUCCESS',
    GET_PROJECT_ACCOUNTS_FAILURE: 'GET_PROJECT_ACCOUNTS_FAILURE',
    CREATE_PROJECT_ACCOUNTS_START: 'CREATE_PROJECT_ACCOUNTS_START',
    CREATE_PROJECT_ACCOUNTS_SUCCESS: 'CREATE_PROJECT_ACCOUNTS_SUCCESS',
    CREATE_PROJECT_ACCOUNTS_FAILURE: 'CREATE_PROJECT_ACCOUNTS_FAILURE',
    DELETE_PROJECT_ACCOUNTS_START: 'DELETE_PROJECT_ACCOUNTS_START',
    DELETE_PROJECT_ACCOUNTS_SUCCESS: 'DELETE_PROJECT_ACCOUNTS_SUCCESS',
    DELETE_PROJECT_ACCOUNTS_FAILURE: 'DELETE_PROJECT_ACCOUNTS_FAILURE',
    GET_ACCOUNT_DETAILS_START: 'GET_ACCOUNT_DETAILS_START',
    GET_ACCOUNT_DETAILS_SUCCESS: 'GET_ACCOUNT_DETAILS_SUCCESS',
    GET_ACCOUNT_DETAILS_FAILURE: 'GET_ACCOUNT_DETAILS_FAILURE',
};

export default actions;
