import Navbar from '../Shared/Header/Navbar';
import Typography from '@mui/material/Typography';
import About from "../About";
import ChooseUs from "../ChooseUs";
import Contact from "../Contact";
import PopularLocalities from './HomePage/PopularLocalities';
import FeaturedProperties from './HomePage/FeaturedProperties';
import DevelopmentWorks from './HomePage/DevelopmentWorks';
import Gallery from './HomePage/Gallery';
import SimpleFooter from '../Shared/Footer/SimpleFooter';
import {makeStyles} from '@mui/styles';
import {Grid, Paper} from "@mui/material";
import clsx from 'clsx';
import {knImages} from '../../helpers/constant';


import 'antd/dist/antd.css';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export default function DemoPage() {
    const classes = customStyles();
    const settings = {
        dots: true,
        arrows:true,
        autoplay: true,
        autoplaySpeed: 2500,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 775,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    console.log(knImages.map(ph => ph.url))
    return (
        <div>
            <Navbar />
            <div className={clsx(classes.loginRoot)} style={{backgroundColor: '#6DB3F2'}}>
                <Grid container style={{height: '90%'}}>
                    <Grid item className={clsx(classes.gridSize, classes.fullWidth)}>
                        <Paper className={classes.cardSize}>
                            <Grid align='center'>
                                <Typography variant="h4" style={{color: '#00234B', fontWeight: 600, marginBottom: '20px', marginTop: 0}}>
                                    Looking for <span style={{color: '#FF5733'}}>DTCP approved Plots?</span>
                                </Typography>
                                {/* <Typography variant="h5" style={{color: '#26d48c', fontWeight: 500, marginBottom: '20px', marginTop: 0}}>
                                    Login
                                </Typography> */}
                            </Grid>
                            <Typography variant="h5" style={{color: "#00234B", padding: "0.5rem", textAlign: "center", fontWeight: 500}}>Buy budget friendly DTCP approved plots</Typography>
                            <Typography variant="h5" style={{color: "#00234B", padding: "0.5rem", textAlign: "center", fontWeight: 500}}>Location: <span style={{color: '#FF5733', fontWeight: 600}}>Mohanur</span></Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <FeaturedProperties />
            <ChooseUs />
            <div style={{backgroundColor: "#F7F7F7", padding: 20}}>
                <Typography variant="h4" style={{color: "#0818A8", padding: "1.5rem", textAlign: "center", fontWeight: 600}}>Project <span style={{color: '#FF5733'}}>Images</span></Typography>
                <div style={{padding: "1rem 2rem"}}>
                    <Slider {...settings}>
                        {knImages.map(ph => <div><img src={ph.url} style={{padding: '20px', width: 375, height: 375}}/></div>)}
                    </Slider>
                </div>
                <Typography variant="h5" style={{color: "#08A04B", padding: "1.5rem", textAlign: "center", fontWeight: 500}}>Kamatchi Nagar Project</Typography>
            </div>
            <PopularLocalities />
            <Contact />
            <SimpleFooter />
        </div>
    );
}

const customStyles = makeStyles({
    loginRoot: {
        flexGrow: 1,
        height: "65vh",
        backgroundColor: '#F7F7F7',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardTitle: {
        margin: '20px 20px',
        textAlign: 'center',
        fontWeight: 400,
        padding: 20
    },
    profileCardStyle: {
        boxShadow: 'none',
    },
    margin8a: {
        margin: '10px',
    },
    media: {
        height: 250,
    },
    text: {
        fontSize: '18px',
        margin: 2,
        fontWeight: 400
    },
    enquiryHome: {
        flexGrow: 1,
        padding: "80px 0",
    },
    gridSize: {
        display: "flex",
        justifyContent: "center",
    },
    typo: {
        padding: "15px 15px",
        margin: "1rem",
        color: "#000000",
        backgroundColor: "inherit",
        borderRadius: 10,
        boxShadow: "none",
        // textAlign: "center"
    },
    typeAlignCenter: {
        textAlign: "center",
    },
    padding16: {
        padding: "15px 0",
    },
    // block: {
    //     backgroundImage: "url('https://drive.google.com/thumbnail?id=1KmRCz2WnljQSiae-9F-SRBJbl1Tp1FoJ')",
    //     backgroundRepeat: 'no-repeat',
    //     backgroundSize: '100%',
    //     position: 'relative',
    //     height: '80vh',

    //     '&:before': {
    //         backgroundColor: 'rgba(0, 0, 0, 0.5)',
    //         content: '',
    //         display: 'block',
    //         height: '100%',
    //         position: 'absolute',
    //         width: '100%',
    //     },
    // },
    cardSize: {
        maxWidth: "400px",
        padding: 40,
        margin: "auto",
        borderRadius: 10,
        boxShadow: "none",
    },
    fullWidth: {
        width: "100vw"
    },
})
