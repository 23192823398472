import {Card} from 'antd';

const PrimaryCard = props => {
  const {title, component} = props
    return(
    <>
      <Card
        title={title}
        headStyle={{backgroundColor: '#26d48c', borderRadius: '10px 10px 0 0', color: '#FFF'}}
        style={{ borderRadius: '20px' }}
      >
        {component}
      </Card>
    </>
  )
}

export default PrimaryCard
