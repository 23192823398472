import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import {useDispatch} from 'react-redux';
import clsx from "clsx";
import {Link} from "react-router-dom";
import actions from '../../../redux/auth/actions';
import LogoutIcon from '@mui/icons-material/Logout';
import {makeStyles} from "@mui/styles";

const AuthNavbar = (props) => {
    const {value} = props;
    const classes = customStyles();
    const dispatch = useDispatch();
    const loadTasks = () => {dispatch({type: actions.LOGOUT_START});}
    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.navBar}>
                <Toolbar>
                    {/*<Avatar variant='square' alt="Esse Spot" src={image} className={classes.logo} />*/}
                    {/* <Link to={"/dashboard"} className={classes.backBtn}><ArrowBackIosTwoToneIcon fontSize="small" /></Link> */}
                    <Typography variant="h5" className={clsx(classes.title)}>
                        {value}
                    </Typography>
                    <Button style={{color: '#fff'}} onClick={() => loadTasks()}><LogoutIcon /></Button>
                    
                </Toolbar>
            </AppBar> 
        </div>
    );
}

export default AuthNavbar

const customStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    navBar: {
        backgroundColor: "#FF5733",
        boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 4%)",
    },
    title: {
        flexGrow: 1,
        color: "#FFF",
        fontWeight: 400,
    },
    margin60: {
        margin: "0 60px",
    },
    backBtn: {
        height: 42,
        width: 42,
        color: "#151B30",
        borderRadius: 50,
        padding: 10,
        backgroundColor: "#F7F7FF",
    }
})
