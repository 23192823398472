import React, {useEffect, useState} from 'react';
import {TextField} from '@mui/material';
import {makeStyles} from "@mui/styles";
import {Button, Grid, Typography} from '@mui/material'
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import clsx from "clsx";
import {Breadcrumb} from "antd";
import actions from '../../../redux/main/projects/actions';
import AuthNavbar from '../../Shared/Header/AuthNavbar';
import {PrimaryDrawer} from "../../Shared/Drawer/PrimaryDrawer";
import {mainMenus} from "../../../helpers/constant";
import DashboardDrawer from '../drawer';
import { ExpensesTable } from './ExpensesTable';
import {AccountsTable} from '../Projects/AccountsTable';
import {serverTimestamp} from "firebase/firestore";


const ProjectDetails = (props) => {
    const {project} = props
    const projectId = project.match.params.projectId
    const {projectDetails} = useSelector(state => state.projectsReducer);
    const {projectExpenses} = useSelector(state => state.projectsReducer);
    const {projectAccounts} = useSelector(state => state.projectsReducer);
    
    const [accountsVisible, setAccountsVisible] = useState(false);
    const [from, setFrom] = useState(false);
    const [to, setTo] = useState(false);
    const [amount, setAmount] = useState(false);
    const [accDate, setAccDate] = useState(false);
    const [projectName, setProjectName] = useState(false);
    const [type, setType] = useState(false);

    const [expensesVisible, setExpensesVisible] = useState(false);
    const [name, setName] = useState(false);
    const [value, setValue] = useState(false);
    const [date, setDate] = useState(false);
    const [remarks, setRemarks] = useState(false);

    const dispatch = useDispatch();
    const handleAccountsOpen = () =>{
        setAccountsVisible(true);
    };
    const handleAccountsClose = () => {
        setAccountsVisible(false);
    };
    const handleExpensesOpen = () =>{
        setExpensesVisible(true);
    };
    const handleExpensesClose = () => {
        setExpensesVisible(false);
    };
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({type: actions.GET_PROJECT_DETAILS_START, projectId: projectId});
        dispatch({type: actions.GET_PROJECT_EXPENSES_START, projectId: projectId});
        dispatch({type: actions.GET_PROJECT_ACCOUNTS_START, projectId: projectId});
    };
    const addAccounts = () => {
        dispatch({
            type: actions.CREATE_PROJECT_ACCOUNTS_START,
            payload: {
                from: from,
                to: to,
                amount: amount,
                date: new Date(Date.parse(accDate)),
                projectId: projectId,
                type: type,
                createdAt: serverTimestamp()
            }
        });
        setAccountsVisible(false);
        dispatch({
            type: actions.GET_PROJECT_ACCOUNTS_START, projectId: projectId
        });
    }

    const addExpenses = () => {
        dispatch({
            type: actions.CREATE_PROJECT_EXPENSE_START,
            payload: {
                projectId: projectId,
                name: name,
                value: value,
                date: new Date(Date.parse(date)),
                remarks: remarks,
                createdAt: serverTimestamp()
            }
        });
        setExpensesVisible(false);
        dispatch({
            type: actions.GET_PROJECT_EXPENSES_START, projectId: projectId
        });
    }

    const classes = customStyles()
    const convertedStartDate = !projectDetails ? new Date() : (projectDetails.startDate === undefined ? new Date() : new Date(projectDetails.startDate.toDate()));
    const convertedEndDate = !projectDetails ? new Date() : (projectDetails.endDate === undefined ? new Date() : new Date(projectDetails.endDate.toDate()));
    
    return(
        <>
            <AuthNavbar value={'Project Details'} />
            <div>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={3} md={3} sm={12} style={{width: '100%', backgroundColor: '#F7F7FF'}}>
                        <DashboardDrawer page={'Projects'} />
                    </Grid>
                    <Grid item lg={9} md={8} sm={12} style={{padding: 20}}>
                        <Breadcrumb style={{
                            marginLeft: 20,
                            color: '#151B30',
                            fontSize: '18px'
                        }}>
                            <Breadcrumb.Item><Link to={'/dashboard'}> Dashboard </Link></Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={'/dashboard/projects'}> Projects </Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{projectId}</Breadcrumb.Item>
                        </Breadcrumb>
                        <div style={{margin: 10, padding: 10}}>
                            <Typography variant="h5" style={{fontSize: '20px', fontWeight: 500, padding: '10px', backgroundColor: '#F0F8FF'}}>{'Project Details'}</Typography>
                            <div style={{padding: "0 20px"}}>
                                <Typography variant="h5" style={{fontSize: '24px', fontWeight: 600, padding: '10px 0', color: '#1842b6'}}>{projectDetails.projectName}</Typography>
                                <Typography variant="h6">Location : <span className={classes.primaryTypo}>{projectDetails.location}</span></Typography>
                                <Typography variant="h6">Project Size : <span className={classes.primaryTypo}>{projectDetails.projectSize}</span></Typography>
                                <Typography variant="h6">Project Investment :  <span className={classes.primaryTypo}>{projectDetails.projectValue}</span></Typography>
                                <Typography variant="h6">Expected Returns : <span className={classes.primaryTypo}>{projectDetails.expectedReturns}</span></Typography>
                                <Typography variant="h6">Role : <span className={classes.primaryTypo}>{projectDetails.role}</span></Typography>
                                <Typography variant="h6">Status : <span className={classes.primaryTypo}>{projectDetails.status}</span></Typography>
                                <Typography variant="h6">Start Date : <span className={classes.primaryTypo}>{convertedStartDate.toLocaleDateString()}</span></Typography>
                                <Typography variant="h6">End Date : <span className={classes.primaryTypo}>{convertedEndDate.toLocaleDateString()}</span></Typography>
                            </div>
                        </div>
                        <div style={{margin: 10, padding: 10}}>  
                            <Typography variant="h5" style={{fontSize: '20px', fontWeight: 500, padding: '10px', backgroundColor: '#F0F8FF'}}>{'Project Accounts'}</Typography>
                            <Button
                                variant={'contained'}
                                style={{
                                    margin: 20,
                                    textTransform: 'capitalize',
                                    color: '#FFFFFF'
                                }}
                                onClick={() => handleAccountsOpen()}
                            >
                                Add Accounts
                            </Button>
                            <AccountsTable accounts={projectAccounts} />
                        </div>
                        <div style={{margin: 10, padding: 10}}>  
                            <Typography variant="h5" style={{fontSize: '20px', fontWeight: 500, padding: '10px', backgroundColor: '#F0F8FF'}}>{'Project Expenses'}</Typography>
                            <Button
                                variant={'contained'}
                                style={{
                                    margin: 20,
                                    textTransform: 'capitalize',
                                    color: '#FFFFFF'
                                }}
                                onClick={() => handleExpensesOpen()}
                            >
                                Add Expenses
                            </Button>
                            <ExpensesTable expenses={projectExpenses} />
                        </div>
                        <PrimaryDrawer
                                anchor={'right'}
                                open={accountsVisible}
                                variant={'temporary'}
                                handleClose={() => handleAccountsClose()}
                                title={'Add Account Detail'}
                                component={
                                    <>
                                        <TextField
                                            placeholder='Project Name'
                                            disabled
                                            variant={'standard'}
                                            value={projectDetails.projectName}
                                            style={{marginTop: 40}}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setProjectName(projectDetails.projectName)}
                                        />
                                        <TextField
                                            placeholder='From'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setFrom(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='To'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setTo(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Amount'
                                            type='number'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Date'
                                            type='date'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setAccDate(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Type'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setType(e.target.value)}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                width: '100%',
                                                padding: '10px 16px',
                                                textAlign: 'left',
                                                left: 0,
                                                background: '#fff',
                                                borderRadius: '0 0 4px 4px',
                                            }}
                                        >
                                            <Button
                                                key="yes"
                                                type='submit'
                                                color='primary'
                                                variant="contained"
                                                className={classes.modalBtn}
                                                onClick={() => addAccounts()}
                                            >
                                                Add Accounts Data
                                            </Button>
                                        </div>
                                    </>
                                }
                            />

                            <PrimaryDrawer
                                anchor={'right'}
                                open={expensesVisible}
                                variant={'temporary'}
                                handleClose={() => handleExpensesClose()}
                                title={'Add Expense'}
                                component={
                                    <>
                                        <TextField
                                            placeholder='Project Name'
                                            disabled
                                            variant={'standard'}
                                            value={projectDetails.projectName}
                                            style={{marginTop: 40}}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setProjectName(projectDetails.projectName)}
                                        />
                                        <TextField
                                            placeholder='Expense'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setName(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Amount Spent'
                                            type='number'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setValue(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Date'
                                            type='date'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setDate(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Remarks'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setRemarks(e.target.value)}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                width: '100%',
                                                padding: '10px 16px',
                                                textAlign: 'left',
                                                left: 0,
                                                background: '#fff',
                                                borderRadius: '0 0 4px 4px',
                                            }}
                                        >
                                            <Button
                                                key="yes"
                                                type='submit'
                                                color='primary'
                                                variant="contained"
                                                className={classes.modalBtn}
                                                onClick={() => addExpenses()}
                                            >
                                                Add Expense
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default ProjectDetails

const customStyles = makeStyles({
    margin20: {
        margin: '20px',
    },
    modalBtn: {
        minWidth: 70,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        textTransform: 'capitalize',
        fontWeight: 500,

        '&:hover': {
            boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        },
    },
    primaryTypo: {
        padding: '8px 10px',
        fontWeight: 600
    },
})
