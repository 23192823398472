import Typography from "@mui/material/Typography";
import {Grid} from "@mui/material";
import {makeStyles} from '@mui/styles';
import EnquiryForm from "./MainPage/EnquiryForm";

const Contact = () => {
    const classes = customStyles();
    return (
        <div style={{backgroundColor: "#fff", padding: 20}}>
            <Typography variant="h4" style={{color: "#0818A8", padding: "1.5rem", textAlign: "center", fontWeight: 600}}>Contact <span style={{color: '#FF5733'}}>Us</span></Typography>
            <Grid container className={classes.flexCenter}>
                <Grid item lg={6} md={6} sm={12}>
                    <div style={{padding: 20}}>
                        <Typography variant="h6" style={{fontSize: "18px", color: "#0818A8", padding: 5}}>Office Address</Typography>
                        <Typography variant="h5" style={{fontSize: "26px", color: "#000", padding: 5, fontWeight: 500}}>Nexa Properties</Typography>
                        <Typography variant="h5" style={{fontSize: "22px", padding: 5}}>College Road,</Typography>
                        <Typography variant="h5" style={{fontSize: "22px", padding: 5}}>Paramathi Velur Taluk,</Typography>
                        <Typography variant="h5" style={{fontSize: "22px", padding: 5}}>Namakkal District - 638 182</Typography>
                        <Typography variant="h5" style={{fontSize: "22px", padding: 5, fontWeight: 500}}>Cell: 76676 23456</Typography>
                        <Typography variant="h5" style={{fontSize: "22px", padding: 5, fontWeight: 500}}>Cell: 96779 92240</Typography>
                    </div>
                </Grid>
                <Grid item lg={6} md={6} sm={12} style={{padding: 20}}>
                    <EnquiryForm />
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d851.2285932761991!2d78.00560375763594!3d11.114421041709688!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1698563735301!5m2!1sen!2sin" width="550" height="450" styles="border:1;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
                </Grid>
            </Grid>
        </div>
    );
};

export default Contact;

const customStyles = makeStyles({
    flexCenter: {
        display: "flex",
        justifyContent: "center",
        padding: "2rem",
    },
})
