import {Box, Grid, Typography} from "@mui/material";
import {Link} from "react-router-dom";
import {footerLeftMenus, footerMidMenus, footerRightMenus} from "../../helpers/constant";


const Footer = () => {
    return(
        <>
            <Box component="footer" style={{backgroundColor: "#F1F4F9", padding: 20, margin: 20, borderRadius: 50}}>
                <div style={{backgroundColor: "#F1F4F9"}}>
                <Grid container>
                    <Grid item xs={12} md={4.5}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography variant='h6' style={{color: '#00234B', fontSize:'22px', fontWeight: 400, paddingBottom: 10}}>
                                <span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>MATRIX</span>
                            </Typography>
                            <div>
                                <Typography style={{fontSize: '18px', padding: 0, color: '#1A1C1E'}}>We focus on delivering agricultural lands, plots, houses, commercial properties and so on all over Tamilnadu especially in Paramathi Velur, Mohanur and surroundings of Namakkal and Karur District</Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '20px', margin: '0.5em', color: '#1A1C1E', fontWeight: 500}}>{footerLeftMenus.menu}</Typography>
                            <div style={{paddingLeft: 10}}>
                                {footerLeftMenus.sub.map(menus => (
                                    <Typography style={{fontSize: '16px', padding: 5}}><Link to={menus.link} style={{color: '#1A1C1E', textDecoration: 'none'}}>{menus.menu}</Link></Typography>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '20px', margin: '0.5em', color: '#1A1C1E', fontWeight: 500}}>{footerMidMenus.menu}</Typography>
                            <div style={{paddingLeft: 10}}>
                                {footerMidMenus.sub.map(menus => (
                                    <Typography style={{fontSize: '16px', padding: 5}}><Link to={menus.link} style={{color: '#1A1C1E', textDecoration: 'none'}}>{menus.menu}</Link></Typography>
                                ))}
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={3.5}>
                        <div style={{padding: 10, margin: 10}}>
                            <Typography style={{fontSize: '20px', margin: '0.5em', color: '#1A1C1E', fontWeight: 500}}>Office Address</Typography>
                            <div style={{paddingLeft: 10, color: "#FFF"}}>
                                <Typography variant="h5" style={{fontSize: "18px", padding: 5, fontWeight: 500}}>Ace Matrix</Typography>
                                <Typography variant="h5" style={{fontSize: "16px", padding: 5}}>69/2 South Street Vellalapalayam, Paramathi Velur Taluk,</Typography>
                                <Typography variant="h5" style={{fontSize: "16px", padding: 5}}>Namakkal District - 637 207</Typography>
                                <Typography variant="h5" style={{fontSize: "16px", padding: 5, fontWeight: 500}}>Cell: 96779 92240</Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div style={{padding: 10, margin: 10}}>
                <Typography
                variant="h6"
                align="center"
                style={{fontWeight: 400, color: '#1A1C1E', fontSize: '16px'}}
                >
                We are financial service company | Ace Matrix | acematrix.in | Privacy Policy | Terms & Conditions
                </Typography>
                <Typography
                    variant="subtitle1"
                    align="center"
                    // className={classes.margin8}
                    style={{color: '#1A1C1E', fontSize: '16px', backgroundColor: '#F1F4F9'}}
                >
                    {'Copyright © '}
                    <Link href="https://www.acematrix.in/">
                        Ace Matrix
                    </Link>{' '}
                    {new Date().getFullYear()}
                    {'.'}
                </Typography>
            </div>
            </Box>
            <div style={{padding: 10}}></div>
        </>
    );
}

export default Footer;
