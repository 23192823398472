import {all, call, put, takeEvery} from 'redux-saga/effects';
import {collection, getDocs, setDoc, doc} from "firebase/firestore";
import actions from './actions';
import {fbFirestore} from "../../../helpers/firebaseClient";


export function* createExpense(params) {
    const randNum = Math.floor((Math.random() * 10000) + 1);
    try {
        yield call(() => setDoc(doc(fbFirestore, 'office-expenses', `${'EX' + randNum}`), params.payload))
        yield put({ type: actions.CREATE_EXPENSE_SUCCESS });
    } catch (error) {
        yield put({ type: actions.CREATE_EXPENSE_FAILURE });
        return error;
    }
}

export function* getExpenses() {
    try {
        const response = yield call(() => getDocs(collection(fbFirestore, 'office-expenses')))
        const data = response.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
        yield put({
            type: actions.GET_EXPENSES_SUCCESS,
            payload: data,
        });
    } catch (error) {
        console.log(error)
        yield put({ type: actions.GET_EXPENSES_FAILURE });
        return error;
    }
}

export default function* mainSaga() {
    yield all([
        takeEvery(actions.CREATE_EXPENSE_START, createExpense),
        takeEvery(actions.GET_EXPENSES_START, getExpenses),
    ]);
}
