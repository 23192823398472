const actions = {
    GET_EXPENSES_START: 'GET_EXPENSES_START',
    GET_EXPENSES_SUCCESS: 'GET_EXPENSES_SUCCESS',
    GET_EXPENSES_FAILURE: 'GET_EXPENSES_FAILURE',
    CREATE_EXPENSE_START: 'CREATE_EXPENSE_START',
    CREATE_EXPENSE_SUCCESS: 'CREATE_EXPENSE_SUCCESS',
    CREATE_EXPENSE_FAILURE: 'CREATE_EXPENSE_FAILURE',
    DELETE_EXPENSE_START: 'DELETE_EXPENSE_START',
    DELETE_EXPENSE_SUCCESS: 'DELETE_EXPENSE_SUCCESS',
    DELETE_EXPENSE_FAILURE: 'DELETE_EXPENSE_FAILURE',
};

export default actions;
