import actions from './actions';

const initState = {
    loader: false,
    investments: [],
    investmentDetails: {investedAmount:[]},
};

export default function mainReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_INVESTMENTS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_INVESTMENTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                investments: action.payload,
            };
        }
        case actions.GET_INVESTMENTS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_INVESTMENT_DETAILS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_INVESTMENT_DETAILS_SUCCESS: {
            console.log(action.payload)
            return {
                ...state,
                loader: false,
                investmentDetails: action.payload,
            };
        }
        case actions.GET_INVESTMENT_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
