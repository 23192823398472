import React, {useState} from 'react';
import clsx from 'clsx';
import {AppBar, Toolbar, Grid, Paper, TextField, Button, Typography} from '@mui/material';
import {useDispatch} from 'react-redux';
import actions from '../../redux/auth/actions';
import {makeStyles} from '@mui/styles';
import {history} from '../../redux/store';
import {Link} from "react-router-dom";
import AuthNavbar from '../Shared/Header/AuthNavbar';
import Disclaimer from '../Disclaimer';


const Login = () => {
    const [email, setEmailAddress] = useState(null);
    const [password, setPassword] = useState(null);
    const dispatch = useDispatch();
    const handleSubmit = () =>{
        dispatch({ type: actions.LOGIN_START, loginData:{email: email, password: password}});
        setInterval(() => history.push('/dashboard'), 1000);
    }
    const classes = customStyles();
    return(
        <>
            {/* <div className={classes.root}>
                <AppBar position='static' className={classes.navBar}>
                    <Toolbar>
                        <Typography variant='h5' className={classes.title}>
                            <span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>MATRIX</span>
                        </Typography>                     
                    </Toolbar>
                </AppBar> 
            </div> */}
            <Grid container>
                <Grid item className={clsx(classes.gridSize, classes.fullWidth)}>
                    <Paper className={classes.cardSize}>
                        <Grid>
                            <Typography variant='h6' style={{color: '#00234B', fontSize:'16px', fontWeight: 400}}>
                                <span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>MATRIX</span>
                            </Typography>
                            <Typography variant='h4' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>Sign in</Typography>
                        </Grid>
                        <TextField
                            label='Email Address'
                            placeholder='Enter email address'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setEmailAddress(e.target.value)}
                        />
                        <TextField
                            label='Password'
                            placeholder='Enter password'
                            type='password'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Link to={'/forgot-password'}>
                            <Typography variant="h6" style={{color: '#1890FF', fontSize: '16px'}}>
                                Forgot your password?
                            </Typography>
                        </Link>
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            fullWidth
                            className={classes.postBtn}
                            onClick={() => handleSubmit()}
                        >
                            Login
                        </Button>
                        <Typography variant='h6' style={{color: '#00234B', fontSize: '16px', margin: '10px 0'}}>Don't have an account? <Link to={'/signup'}>Sign up</Link></Typography>
                        <Typography variant='h6' style={{color: '#00234B', fontSize: '16px'}}><Link to={'/'} className={clsx(classes.margin8a, classes.textDecoration)}>Back to Home</Link></Typography>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default Login

const customStyles = makeStyles({
    title: {
        flexGrow: 1,
        color: '#FFF',
        fontWeight: 600,
    },
    margin8: {
        margin: '10px 0',
    },
    fullWidth: {
        width: '100vw'
    },
    postBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '10px 0px',
        boxShadow: 'none',
        borderRadius: 20,
        color: '#FFF',
        backgroundColor: '#00B050',
        fontWeight: 600,

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#EBF5F0',
            color: '#00B050',
        },
    },
    loginRoot: {
        flexGrow: 1,
        height: '100vh',
        backgroundColor: '#F7F7F7',
    },
    gridSize: {
        height: '600px',
        display: 'flex',
        justifyContent: 'center',
    },
    cardSize: {
        maxWidth: '400px',
        padding: 40,
        margin: 'auto',
        borderRadius: 20,
        boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 4%)',
    },
})
