import React, {useEffect, useState} from 'react'
import {Box, FormControl, Grid, InputLabel, MenuItem, Select, Paper, Typography, Tabs, Tab} from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {useDispatch, useSelector} from 'react-redux';
import actions from '../../redux/main/scripts/actions';
import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import DashboardDrawer from '../MainPage/drawer';
import {Breadcrumb, Col} from "antd";
import PrimaryCard from "../Shared/Card/PrimaryCard";
import Button from "@mui/material/Button";
import clsx from "clsx";
import {serverTimestamp} from "firebase/firestore";
import {IndexTable} from './IndexTable';
import {DiscoverTable} from './DiscoverTable';
import {NiftyTable} from './NiftyTable';
import {NiftyNextTable} from './Next50Table';
import {NiftyMidcapTable} from './MidcapTable';
import Navbar from '../Header/Navbar';

const Discover = () => {
    const {scripts, futures, indices, nifty, niftyNext, niftyMidcap} = useSelector(state => state.scriptsReducer);
    const [value, setValue] = useState(1);
    const handleChange = (e, value) => {
        console.log(value)
        setValue(value);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({
            type: actions.GET_SCRIPTS_START,
        });
    }
    const classes = customStyles();
    return(
        <>
            {/* <AuthNavbar value={'Scripts'} /> */}
            <Navbar />
            <div style={{paddingTop: 60}}></div>
            <Grid style={{padding: 16, backgroundColor: '#FEF7EA'}}>
                <Typography variant="h4" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Indices</Typography>
                <Grid container>
                    {indices.map((data) => (
                        <Grid item xs={12} sm={6} md={4} lg={2} key={data.companyName}>
                            <Paper style={{margin: '1.5rem', padding: '1rem', color:'#1842B6', boxShadow: "0 0 60px rgba(0, 0, 0, .2)"}}>
                                <Typography variant="h6" style={{fontWeight: 600, fontSize: '18px'}}>
                                    {data.index}
                                </Typography>
                                <Grid container >
                                    <Grid item xs={12} md={12} lg={12}>
                                        <Typography variant="h6" className={classes.padding8}>
                                            <span style={{fontWeight: 600, fontSize: '20px', color: (data.change < 0 ? '#FF5733' : '#34A853')}}>{data.cmp}</span> <span style={{fontSize: '16px'}}>{data.change} ({data.changepct})</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <div style={{marginTop: 10}}>
                <TabContext value={value}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable-tabs"
                    >
                        <Tab label="Indices" value={1} />
                        <Tab label="Nifty 50" value={2} />
                        <Tab label="Futures" value={3} />
                        <Tab label="Nifty Next 50" value={4} />
                        <Tab label="Nifty Midcap 50" value={5} />
                        <Tab label="ETF" value={6} />
                        <Tab label="Mutual Funds" value={7} />
                    </Tabs>
                
                    <TabPanel value={1}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Index</Typography>
                        <div>
                            <IndexTable indices={indices} />
                        </div>
                    </TabPanel>
                    <TabPanel value={2}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Nifty 50</Typography>
                        <div>
                            <NiftyTable nifty={nifty} />
                        </div>
                    </TabPanel>
                    <TabPanel value={3}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Futures</Typography>
                        <div>
                            <DiscoverTable scripts={scripts} />
                        </div>
                    </TabPanel>
                    <TabPanel value={4}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Nifty Next 50</Typography>
                        <div>
                            <NiftyNextTable niftyNext={niftyNext} />
                        </div>
                    </TabPanel>
                    <TabPanel value={5}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Nifty Midcap 50</Typography>
                        <div>
                            <NiftyMidcapTable niftyMidcap={niftyMidcap} />
                        </div>
                    </TabPanel>
                    <TabPanel value={6}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>ETFs</Typography>
                        <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                    </TabPanel>
                    <TabPanel value={7}>
                        <Typography variant="h5" style={{color: "#0c5aa5", padding: "1rem", textAlign: "center", fontWeight: 600}}>Mutual Funds</Typography>
                        <Typography variant="h6" style={{color: "#020202", padding: "1rem", textAlign: "center", fontWeight: 500}}>Will be updated soon</Typography>
                    </TabPanel>
                </TabContext>
            </div>
        </>
    )
}

export default Discover

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: "40px",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
