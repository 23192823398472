import React, {useEffect, useState} from 'react'
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material'
import {useDispatch, useSelector} from 'react-redux';
import actions from '../../../redux/main/accounts/actions';
import AuthNavbar from "../../Shared/Header/AuthNavbar";
import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import DashboardDrawer from '../drawer';
import {Breadcrumb, Col} from "antd";
import PrimaryCard from "../../Shared/Card/PrimaryCard";
import Button from "@mui/material/Button";
import {AccountsTable} from '../Projects/AccountsTable';
import {PrimaryDrawer} from "../../Shared/Drawer/PrimaryDrawer";
import clsx from "clsx";
import {serverTimestamp} from "firebase/firestore";

const Accounts = () => {
    const {accounts} = useSelector(state => state.accountsReducer);
    const [visible, setVisible] = useState(false);
    const [from, setFrom] = useState(false);
    const [to, setTo] = useState(false);
    const [amount, setAmount] = useState(false);
    const [date, setDate] = useState(false);
    const [projectName, setProjectName] = useState(false);
    const [type, setType] = useState(false);
    const handleOpen = () =>{
        setVisible(true);
    };
    const handleClose = () => {
        setVisible(false);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({
            type: actions.GET_ACCOUNTS_START,
        });
    }
    const addAccounts = () => {
        dispatch({
            type: actions.CREATE_ACCOUNTS_START,
            payload: {
                from: from,
                to: to,
                amount: amount,
                date: date,
                projectName: projectName,
                type: type,
                createdAt: serverTimestamp()
            }
        });
        setVisible(false);
        dispatch({
            type: actions.GET_ACCOUNTS_START,
        });
    }
    const classes = customStyles();
    return(
        <>
            <AuthNavbar value={'Accounts'} />
            <div>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={3} md={3} sm={12} style={{width: '100%', backgroundColor: '#F7F7FF'}}>
                        <DashboardDrawer page={'Accounts'} />
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} style={{padding: 20}}>
                        <Breadcrumb style={{
                            marginLeft: 20,
                            color: '#26d48c',
                            fontSize: '18px'
                        }}>
                            <Breadcrumb.Item><Link to={'/dashboard'}> Dashboard </Link></Breadcrumb.Item>
                            <Breadcrumb.Item> Accounts </Breadcrumb.Item>
                        </Breadcrumb>
                        <div className={classes.margin20}>
                            <PrimaryCard
                                title={'Accounts'}
                                component={
                                    <>
                                        <Button
                                            variant={'contained'}
                                            style={{
                                                marginBottom: 20,
                                                textTransform: 'capitalize',
                                                color: '#FFFFFF'
                                            }}
                                            onClick={() => handleOpen()}
                                        >
                                            Add Accounts Data
                                        </Button>
                                        <AccountsTable accounts={accounts} />
                                    </>
                                }
                            />
                            
                            <PrimaryDrawer
                                anchor={'right'}
                                open={visible}
                                variant={'temporary'}
                                handleClose={() => handleClose()}
                                title={'Add Account Detail'}
                                component={
                                    <>
                                        <TextField
                                            placeholder='From'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            style={{marginTop: 40}}
                                            onChange={(e) => setFrom(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='To'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setTo(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Amount'
                                            type='number'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Project Name'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setProjectName(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Date'
                                            type='date'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setDate(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Type'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setType(e.target.value)}
                                        />
                                        <div
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                width: '100%',
                                                padding: '10px 16px',
                                                textAlign: 'left',
                                                left: 0,
                                                background: '#fff',
                                                borderRadius: '0 0 4px 4px',
                                            }}
                                        >
                                            <Button
                                                key="yes"
                                                type='submit'
                                                color='primary'
                                                variant="contained"
                                                className={classes.modalBtn}
                                                onClick={() => addAccounts()}
                                            >
                                                Add Accounts Data
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Accounts

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: "40px",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
