import {StyledEngineProvider, ThemeProvider, CssBaseline} from "@mui/material";

import theme from "./assets/styles/theme/theme"

import AppRouter from "./routes";

const App = () => {
  return (
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider
            theme={theme}
        >
          <AppRouter />
        </ThemeProvider>
      </StyledEngineProvider>
  );
}

export default App;
