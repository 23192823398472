import {BrowserRouter, Route, Switch, NavLink} from "react-router-dom";

import Login from "../components/Login/index";
import WelcomePage from "../components/WelcomePage";
import DemoPage from "../components/MainPage/demo";
import HomePage from "../components/MainPage";
import Partners from "../components/MainPage/Partners";
import Investors from "../components/MainPage/Investors";
import Employees from "../components/MainPage/Employees";
import Investments from "../components/MainPage/Investments";
import Projects from "../components/MainPage/Projects";
import Team from "../components/MainPage/Team";
import InvestorDetails from "../components/MainPage/Investors/InvestorDetails";
import InvestmentDetails from "../components/MainPage/Investments/InvestmentDetails";
import ProjectDetails from "../components/MainPage/Projects/ProjectDetails";
import TeamDetails from "../components/MainPage/Team/TeamDetails";
import Accounts from "../components/MainPage/Accounts";
import AccountDetails from "../components/MainPage/Accounts/AccountDetails";
import ExpenseManager from "../components/MainPage/ExpenseManager";
import Dashboard from "../components/MainPage/dashboard";
import Profile from "../components/MainPage/MyProfile";
import Customers from "../components/MainPage/Customers";
import OfficeDocs from "../components/MainPage/docs";

import PartnerLogin from "../components/OnBoarding/Login";
import PartnerRegister from "../components/OnBoarding/Register";

import PropertyDetails from '../components/MainPage/Properties/Property'
import Discover from "../components/Discover";


const PrivateRoute = ({component:Component, ...rest}) => {
    const sessionStorage = JSON.parse(window.sessionStorage.getItem('auth'))
    const auth = sessionStorage === null ? {isUserLoggedIn: false} : sessionStorage
    return(
        <Route {...rest} render={ props =>
            (auth.isUserLoggedIn) ?
                <Component {...props} />
                :
                <NavLink to="/login" />
        }/>
    );
}

const AppRouter = () => {
    return (
        <BrowserRouter>
            <Switch>
                {/*Main Page*/}
                <Route exact path="/" component={WelcomePage}/>

                <Route exact path="/customers/login" component={Login}/>
                <Route exact path="/customers/registration" component={PartnerRegister}/>

                <Route exact path="/discover" component={Discover}/>

                {/*Dashboard*/}
                <Route exact path="/login" component={Login}/>
                <Route exact path="/signup" component={PartnerRegister}/>

                <PrivateRoute exact path="/dashboard" component={Dashboard}/>
                <PrivateRoute exact path="/dashboard/projects" component={Projects} />
                <PrivateRoute exact path="/dashboard/investments" component={Investments} />
                <PrivateRoute exact path="/dashboard/team/partners" component={Partners} />
                <PrivateRoute exact path="/dashboard/team/investors" component={Investors} />
                <PrivateRoute exact path="/dashboard/team/employees" component={Employees} />
                <PrivateRoute exact path="/dashboard/customers" component={Customers} />
                <PrivateRoute exact path="/dashboard/expenses" component={ExpenseManager} />
                <PrivateRoute exact path="/dashboard/documents" component={OfficeDocs} />

                <PrivateRoute exact path="/dashboard/investors/:investorId" component={(investorId) => <InvestorDetails investor={investorId} />}/>
                <PrivateRoute exact path="/dashboard/investments/:investmentId" component={(investmentId) => <InvestmentDetails investment={investmentId} />}/>
                <PrivateRoute exact path="/dashboard/projects/:projectId" component={(projectId) => <ProjectDetails project={projectId} />}/>
                {/* <PrivateRoute exact path="/dashboard/team/:teamId" component={(teamId) => <TeamDetails team={teamId} />}/> */}
                {/* <PrivateRoute exact path="/dashboard/accounts/:dataId" component={(dataId) => <AccountDetails data={dataId} />}/> */}
            </Switch>
        </BrowserRouter>
    )
}

export default AppRouter
