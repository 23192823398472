const actions = {
    GET_TEAM_START: 'GET_TEAM_START',
    GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
    GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',
    CREATE_TEAM_START: 'CREATE_TEAM_START',
    CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
    CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',
    DELETE_TEAM_START: 'DELETE_TEAM_START',
    DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
    DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE',
    GET_TEAM_DETAILS_START: 'GET_TEAM_DETAILS_START',
    GET_TEAM_DETAILS_SUCCESS: 'GET_TEAM_DETAILS_SUCCESS',
    GET_TEAM_DETAILS_FAILURE: 'GET_TEAM_DETAILS_FAILURE',
};

export default actions;
