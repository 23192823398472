import {Drawer} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {PrimaryNavbar} from '../Navbar/PrimaryNavbar';
import {PrimaryIconButton} from '../IconButton/PrimaryIconButton';
import {PrimaryTypography} from '../Typograhy/PrimaryTypography';
import {makeStyles} from "@mui/styles";


const drawerWidth = 400;

export const PrimaryDrawer = props => {
    const {anchor, classes, component, elevation, footer, handleClose, open, title, variant} = props
    const customClasses = customStyles()
    return(
        <>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                anchor={anchor || 'right'}
                classes={{root: classes}}
                elevation={elevation || 16}
                open={open || false}
                variant={variant || 'temporary'}
                onClose={handleClose}
                footer={footer}
            >
                <PrimaryNavbar
                    color={'primary'}
                    position={'static'}
                    disableGutters={false}
                    variant={'regular'}
                    component={
                        <>
                            <PrimaryTypography
                                align={'left'}
                                classes={customClasses.margin8}
                                variant={'h6'}
                                textValue={title}
                            />
                            <PrimaryIconButton
                                aria-label={'back'}
                                disabled={false}
                                edge={'start'}
                                size={'large'}
                                handleClose={handleClose}
                                iconComponent={
                                    <CloseIcon />
                                }
                            />
                        </>
                    }
                />
                {component}
            </Drawer>
        </>
    )
}

const customStyles = makeStyles({
    margin8: {
        margin: '10px 0',
    },
})
