const actions = {
    GET_SCRIPTS_START: 'GET_SCRIPTS_START',
    GET_SCRIPTS_SUCCESS: 'GET_SCRIPTS_SUCCESS',
    GET_SCRIPTS_FAILURE: 'GET_SCRIPTS_FAILURE',
    GET_SCRIPT_DETAILS_START: 'GET_SCRIPT_DETAILS_START',
    GET_SCRIPT_DETAILS_SUCCESS: 'GET_SCRIPT_DETAILS_SUCCESS',
    GET_SCRIPT_DETAILS_FAILURE: 'GET_SCRIPT_DETAILS_FAILURE',
    GET_INDICES_START: 'GET_INDICES_START',
    GET_INDICES_SUCCESS: 'GET_INDICES_SUCCESS',
    GET_INDICES_FAILURE: 'GET_INDICES_FAILURE',
};

export default actions;
