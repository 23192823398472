const actions = {
    // GET_INVESTORS_START: 'GET_INVESTORS_START',
    // GET_INVESTORS_SUCCESS: 'GET_INVESTORS_SUCCESS',
    // GET_INVESTORS_FAILURE: 'GET_INVESTORS_FAILURE',
    // CREATE_INVESTORS_START: 'CREATE_INVESTORS_START',
    // CREATE_INVESTORS_SUCCESS: 'CREATE_INVESTORS_SUCCESS',
    // CREATE_INVESTORS_FAILURE: 'CREATE_INVESTORS_FAILURE',
    DELETE_INVESTORS_START: 'DELETE_INVESTORS_START',
    DELETE_INVESTORS_SUCCESS: 'DELETE_INVESTORS_SUCCESS',
    DELETE_INVESTORS_FAILURE: 'DELETE_INVESTORS_FAILURE',
    GET_INVESTOR_DETAILS_START: 'GET_INVESTOR_DETAILS_START',
    GET_INVESTOR_DETAILS_SUCCESS: 'GET_INVESTOR_DETAILS_SUCCESS',
    GET_INVESTOR_DETAILS_FAILURE: 'GET_INVESTOR_DETAILS_FAILURE',
    GET_INVESTMENTS_START: 'GET_INVESTMENTS_START',
    GET_INVESTMENTS_SUCCESS: 'GET_INVESTMENTS_SUCCESS',
    GET_INVESTMENTS_FAILURE: 'GET_INVESTMENTS_FAILURE',
    CREATE_INVESTMENTS_START: 'CREATE_INVESTMENTS_START',
    CREATE_INVESTMENTS_SUCCESS: 'CREATE_INVESTMENTS_SUCCESS',
    CREATE_INVESTMENTS_FAILURE: 'CREATE_INVESTMENTS_FAILURE',
    DELETE_INVESTMENTS_START: 'DELETE_INVESTMENTS_START',
    DELETE_INVESTMENTS_SUCCESS: 'DELETE_INVESTMENTS_SUCCESS',
    DELETE_INVESTMENTS_FAILURE: 'DELETE_INVESTMENTS_FAILURE',
    GET_PROJECTS_START: 'GET_PROJECTS_START',
    GET_PROJECTS_SUCCESS: 'GET_PROJECTS_SUCCESS',
    GET_PROJECTS_FAILURE: 'GET_PROJECTS_FAILURE',
    CREATE_PROJECTS_START: 'CREATE_PROJECTS_START',
    CREATE_PROJECTS_SUCCESS: 'CREATE_PROJECTS_SUCCESS',
    CREATE_PROJECTS_FAILURE: 'CREATE_PROJECTS_FAILURE',
    DELETE_PROJECTS_START: 'DELETE_PROJECTS_START',
    DELETE_PROJECTS_SUCCESS: 'DELETE_PROJECTS_SUCCESS',
    DELETE_PROJECTS_FAILURE: 'DELETE_PROJECTS_FAILURE',
    GET_TEAM_START: 'GET_TEAM_START',
    GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
    GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',
    CREATE_TEAM_START: 'CREATE_TEAM_START',
    CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
    CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',
    DELETE_TEAM_START: 'DELETE_TEAM_START',
    DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
    DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE',
};

export default actions;
