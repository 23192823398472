import React, {useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import clsx from 'clsx'
import {Hidden} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import DrawerComponent from "./DrawerComponent";


const Navbar = () => {
    const classes = customStyles();
    const [visible, setVisible] = useState(false);
    const toggleDrawer = () => {
        setVisible(false)
    };
    const handleMobileDrawerOpen = () => {
        setVisible(true)
    };
    const menus = [
        {
            link: "/",
            name: "Home",
            variant: "default",
            button: false,
        },
        {
            link: "/services",
            name: "Services",
            variant: "default",
            button: false,
        },
        {
            link: "/projects",
            name: "Projects",
            variant: "default",
            button: false,
        },
        {
            link: "/login",
            name: "Login",
            variant: "default",
            button: false,
        },
    ];

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.navBar}>
                <Toolbar>
                    <Typography variant="h5" className={classes.title}>
                        NEXA <span style={{color: '#FF5733'}}>PROPERTIES</span>
                    </Typography>
                    <Hidden mdUp>
                        <IconButton
                            className={classes.menuButton}
                            onClick={handleMobileDrawerOpen}
                            aria-label="Open Navigation"
                        >
                            <MenuIcon color="primary" />
                        </IconButton>
                    </Hidden>
                    <Hidden mdDown>
                        {menus.map(element => {
                            if (element.link) {
                                return (
                                    <Link
                                        key={element.name}
                                        to={element.link}
                                        className={clsx(classes.margin8a, classes.textDecoration)}
                                    >
                                        <Button
                                            disableRipple
                                            variant={element.variant}
                                            className={element.button ? classes.postBtn : classes.navLink}
                                        >
                                            {element.name}
                                        </Button>
                                    </Link>
                                );
                            }
                        })}
                    </Hidden>
                </Toolbar>
            </AppBar>
            <DrawerComponent
                open={visible}
                toggleDrawerHandler={toggleDrawer}
            />
        </div>
    );
}

export default Navbar

const customStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
    navBar: {
        boxShadow: "0px 10px 20px 0px rgba(0, 0, 0, 4%)",
    },
    title: {
        flexGrow: 1,
        color: "#0818A8",
        fontWeight: 600,
        fontSize: '28px'
    },
    menuButton: {
        borderRadius: 10,
        backgroundColor: "#f7f7f7",
        color: "#00234B",
        fontSize: 10,

        '&:hover': {
            color: "#000",
            backgroundColor: "#f7f7f7",
        },
    },
    margin8a: {
        margin: "10px",
    },
    navLink: {
        color: "#00234B",
        margin: 0,
        fontSize: "16px",
        fontWeight: 600,
        textDecoration: "none",
        textTransform: "uppercase",

        '&:hover': {
            color: "#FF5733",
            backgroundColor: "inherit",
        }
    },
    textDecoration: {
        textDecoration: "none"
    },
})
