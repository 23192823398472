import React, {useEffect, useState} from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import actions from '../../../redux/main/projects/actions';
import AuthNavbar from "../../Shared/Header/AuthNavbar";
import {Link} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import DashboardDrawer from '../drawer';
import {Breadcrumb, Col} from "antd";
import PrimaryCard from "../../Shared/Card/PrimaryCard";
import Button from "@mui/material/Button";
import {ProjectsTable} from "./ProjectsTable";
import {PrimaryDrawer} from "../../Shared/Drawer/PrimaryDrawer";
import clsx from "clsx";
import { fbStorage } from "../../../helpers/firebaseClient"
import { getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { v4 } from "uuid";
import {serverTimestamp} from 'firebase/firestore';

const Projects = () => {
    const {projects} = useSelector(state => state.projectsReducer);
    const [visible, setVisible] = useState(false);
    const [projectName, setProjectName] = useState(false);
    const [location, setLocation] = useState(false);
    const [role, setRole] = useState(false);
    const [projectValue, setProjectValue] = useState(false);
    const [projectSize, setProjectSize] = useState(false);
    // const [sellingPrice, setSellingPrice] = useState(false);
    const [returns, setReturns] = useState(false);
    const [status, setStatus] = useState(false);
    const [startDate, setStartDate] = useState(false);
    const [endDate, setEndDate] = useState(false);
    const [projectImages, setProjectImages] = useState(false);
    const handleOpen = () =>{
        setVisible(true);
    };
    const handleClose = () => {
        setVisible(false);
    };
    const dispatch = useDispatch();
    useEffect(() => {
        loadTasks();
    }, []);
    const loadTasks = () => {
        dispatch({
            type: actions.GET_PROJECTS_START,
            filter: {
                type: 'role',
                value: 'Director'
            }
        });
    }
    const addProject = () => {
        // const splitTags = tags.split(',')
        // const tg = splitTags.map(i => {return {url: i}})
        // const imgRef =  ref(fbStorage,`files/${v4()}`)
        // uploadBytes(imgRef,projectImages).then(value=>{
        //     const resUrl = getDownloadURL(value.ref).then(url=>{console.log(url)})
        //     console.log(resUrl)
        // })
        
        dispatch({
            type: actions.CREATE_PROJECTS_START,
            payload: {
                projectName: projectName,
                projectValue: projectValue,
                location: location,
                // sellingPrice: sellingPrice,
                projectSize: projectSize,
                returns: returns,
                status: status,
                startDate: startDate,
                endDate: endDate,
                role: role,
                createdAt: serverTimestamp()
            }
        });
        setVisible(false);
        dispatch({
            type: actions.GET_PROJECTS_START,
        });
    }
    const classes = customStyles();
    return(
        <>
            <AuthNavbar value={'Projects'} />
            <div>
                <Grid container className={classes.flexCenter}>
                    <Grid item lg={3} md={3} sm={12} style={{width: '100%', backgroundColor: '#F7F7FF'}}>
                        <DashboardDrawer page={'Projects'} />
                    </Grid>
                    <Grid item lg={9} md={9} sm={12} style={{padding: 20}}>
                        <Breadcrumb style={{
                            marginLeft: 20,
                            color: '#151B30',
                            fontSize: '18px'
                        }}>
                            <Breadcrumb.Item><Link to={'/dashboard'}> Dashboard </Link></Breadcrumb.Item>
                            <Breadcrumb.Item> Projects </Breadcrumb.Item>
                        </Breadcrumb>
                        <div className={classes.margin20}>
                            <PrimaryCard
                                title={'Projects'}
                                component={
                                    <>
                                        <Button
                                            variant={'contained'}
                                            style={{
                                                marginBottom: 20,
                                                textTransform: 'capitalize',
                                                color: '#FFFFFF'
                                            }}
                                            onClick={() => handleOpen()}
                                        >
                                            Add Projects
                                        </Button>
                                        <ProjectsTable projects={projects} />
                                    </>
                                }
                            />
                            
                            <PrimaryDrawer
                                anchor={'right'}
                                open={visible}
                                variant={'temporary'}
                                handleClose={() => handleClose()}
                                title={'Add Project'}
                                component={
                                    <>
                                        <TextField
                                            placeholder='Project Name'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            style={{marginTop: 40}}
                                            onChange={(e) => setProjectName(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Project Size'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setProjectSize(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Project Value'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setProjectValue(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Location'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setLocation(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Role'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setRole(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Status'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setStatus(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Start Date'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setStartDate(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='End Date'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setEndDate(e.target.value)}
                                        />
                                        <TextField
                                            placeholder='Returns'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setReturns(e.target.value)}
                                        />
                                        {/* <TextField
                                            placeholder='Selling Price'
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setSellingPrice(e.target.value)}
                                        /> */}
                                        {/* <TextField
                                            placeholder='Project Images'
                                            type='file'
                                            multiple
                                            required
                                            variant={'standard'}
                                            className={clsx(classes.margin20)}
                                            onChange={(e) => setProjectImages(e.target.files[0])}
                                        /> */}
                                        <div
                                            // style={{
                                            //     position: 'absolute',
                                            //     bottom: 0,
                                            //     width: '100%',
                                            //     padding: '10px 16px',
                                            //     textAlign: 'left',
                                            //     left: 0,
                                            //     background: '#fff',
                                            //     borderRadius: '0 0 4px 4px',
                                            // }}
                                        >
                                            <Button
                                                key="yes"
                                                type='submit'
                                                color='primary'
                                                variant="contained"
                                                className={classes.modalBtn}
                                                onClick={() => addProject()}
                                            >
                                                Add Project
                                            </Button>
                                        </div>
                                    </>
                                }
                            />
                        </div>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}

export default Projects

const customStyles = makeStyles({
    text: {
        fontSize: '16px',
        margin: 2,
        fontWeight: 400
    },
    margin8a: {
        margin: '8px',
    },
    margin20: {
        margin: '20px',
    },
    margin40: {
        margin: "40px",
    },
    flexCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '0px 10px',
        boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
        borderRadius: 25,
        color: '#FFFFFFCC',
        fontWeight: 600,

        '&:hover': {
            color: '#FFFFFFCC',
            boxShadow: 'none',
        },
    },
})
