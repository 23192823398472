import React, {useState} from 'react';
import {Button, Grid, Paper, TextField} from "@mui/material";
import clsx from "clsx";
import {LockOpen} from "@mui/icons-material";
import {useDispatch} from "react-redux";
import {makeStyles} from '@mui/styles';
import actions from "../../redux/main/team/actions";


const EnquiryForm = () => {
    const [name, setName] = useState(null);
    const [contact, setContact] = useState(null);
    const [subject, setSubject] = useState(null);
    const dispatch = useDispatch();
    const handleSubmit = () =>{
        dispatch({ type: actions.LOGIN_START, loginData:{name: name, contact: contact, subject: subject}});
    }
    const classes = customStyles();
    return(
        <Grid item className={clsx(classes.gridSize)}>
            <Paper className={clsx(classes.cardSize, classes.cardBg)}>
                <TextField label='Contact' placeholder='Enter contact number' fullWidth required className={classes.margin8} onChange={(e) => setContact(e.target.value)} />
                <TextField label='Name' placeholder='Enter name' fullWidth required className={classes.margin8} onChange={(e) => setName(e.target.value)} />
                <TextField label='Subject' placeholder='Enter subject' fullWidth required className={classes.margin8} onChange={(e) => setSubject(e.target.value)} />
                <Button
                    type='submit'
                    color='primary'
                    variant="contained"
                    fullWidth
                    // startIcon={<LockOpen />}
                    className={classes.postBtn}
                    onClick={() => handleSubmit()}
                >
                    Submit Enquiry
                </Button>
            </Paper>
        </Grid>
    );
}

export default EnquiryForm

const customStyles = makeStyles({
    margin8: {
        margin: "10px 0",
    },
    postBtn: {
        minWidth: 120,
        padding: "8px 20px",
        margin: "10px 0px",
        boxShadow: "none",
        borderRadius: 10,
        color: "#f7f7f7",
        fontWeight: 600,

        '&:hover': {
            boxShadow: "none",
        },
    },
    gridSize: {
        display: "flex",
        justifyContent: "center",
    },
    cardSize: {
        maxWidth: "450px",
        margin: "auto",
        borderRadius: 10,
        boxShadow: "none",
    },
})
