import actions from './actions';

const initState = {
    loader: false,
    team: [],
    teamDetails: [],
    employees: [],
    partners: [],
    investors: []
};

export default function mainReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_TEAM_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_TEAM_SUCCESS: {
            return {
                ...state,
                loader: false,
                team: action.payload,
            };
        }
        case actions.GET_TEAM_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_EMPLOYEES_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_EMPLOYEES_SUCCESS: {
            return {
                ...state,
                loader: false,
                employees: action.payload,
            };
        }
        case actions.GET_EMPLOYEES_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_PARTNERS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_PARTNERS_SUCCESS: {
            return {
                ...state,
                loader: false,
                partners: action.payload,
            };
        }
        case actions.GET_PARTNERS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_INVESTORS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_INVESTORS_SUCCESS: {
            return {
                ...state,
                loader: false,
                investors: action.payload,
            };
        }
        case actions.GET_INVESTORS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_TEAM_DETAILS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_TEAM_DETAILS_SUCCESS: {
            return {
                ...state,
                loader: false,
                teamDetails: action.payload,
            };
        }
        case actions.GET_TEAM_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
