const actions = {
    GET_TEAM_START: 'GET_TEAM_START',
    GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
    GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',
    CREATE_TEAM_START: 'CREATE_TEAM_START',
    CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
    CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',
    DELETE_TEAM_START: 'DELETE_TEAM_START',
    DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
    DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE',
    GET_TEAM_DETAILS_START: 'GET_TEAM_DETAILS_START',
    GET_TEAM_DETAILS_SUCCESS: 'GET_TEAM_DETAILS_SUCCESS',
    GET_TEAM_DETAILS_FAILURE: 'GET_TEAM_DETAILS_FAILURE',

    GET_EMPLOYEES_START: 'GET_EMPLOYEES_START',
    GET_EMPLOYEES_SUCCESS: 'GET_EMPLOYEES_SUCCESS',
    GET_EMPLOYEES_FAILURE: 'GET_EMPLOYEES_FAILURE',
    CREATE_EMPLOYEES_START: 'CREATE_EMPLOYEES_START',
    CREATE_EMPLOYEES_SUCCESS: 'CREATE_EMPLOYEES_SUCCESS',
    CREATE_EMPLOYEES_FAILURE: 'CREATE_EMPLOYEES_FAILURE',

    GET_PARTNERS_START: 'GET_PARTNERS_START',
    GET_PARTNERS_SUCCESS: 'GET_PARTNERS_SUCCESS',
    GET_PARTNERS_FAILURE: 'GET_PARTNERS_FAILURE',
    CREATE_PARTNERS_START: 'CREATE_PARTNERS_START',
    CREATE_PARTNERS_SUCCESS: 'CREATE_PARTNERS_SUCCESS',
    CREATE_PARTNERS_FAILURE: 'CREATE_PARTNERS_FAILURE',

    GET_INVESTORS_START: 'GET_INVESTORS_START',
    GET_INVESTORS_SUCCESS: 'GET_INVESTORS_SUCCESS',
    GET_INVESTORS_FAILURE: 'GET_INVESTORS_FAILURE',
    CREATE_INVESTORS_START: 'CREATE_INVESTORS_START',
    CREATE_INVESTORS_SUCCESS: 'CREATE_INVESTORS_SUCCESS',
    CREATE_INVESTORS_FAILURE: 'CREATE_INVESTORS_FAILURE',
};

export default actions;
