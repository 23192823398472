import actions from './actions';

const initState = {
    loader: false,
    accounts: [],
    accountsData: [],
    offDocs: []
};

export default function mainReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_ACCOUNTS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_ACCOUNTS_SUCCESS: {
            return {
                ...state,
                loader: false,
                accounts: action.payload,
            };
        }
        case actions.GET_ACCOUNTS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_ACCOUNT_DETAILS_START: {
            return {
                ...state,
                loader: true,
            };
        }
        case actions.GET_ACCOUNT_DETAILS_SUCCESS: {
            console.log(action.payload)
            return {
                ...state,
                loader: false,
                accountsData: action.payload,
            };
        }
        case actions.GET_ACCOUNT_DETAILS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        case actions.GET_DOCS_START: {
            return {
                ...state,
                loader: true,
            }; 
        }
        case actions.GET_DOCS_SUCCESS: {
            return {
                ...state,
                loader: false,
                offDocs: action.payload,
            };
        }
        case actions.GET_DOCS_FAILURE: {
            return {
                ...state,
                loader: false,
            };
        }
        default:
            return state;
    }
}
