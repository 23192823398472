import { all } from 'redux-saga/effects';
import authSaga from "./auth/sagas";
import customersSaga from "./main/customers/sagas";
import investorsSaga from "./main/investors/sagas";
import investmentsSaga from "./main/investments/sagas";
import projectsSaga from "./main/projects/sagas";
import teamSaga from "./main/team/sagas";
import accountsSaga from "./main/accounts/sagas";
import expensesSaga from "./main/expenses/sagas";
import projectWiseInvestmentsSaga from "./main/projectWiseInvestments/sagas";
import postPropertiesSaga from './main/post-property/sagas';
import propertiesSaga from './main/properties/sagas';
import scriptsSaga from './main/scripts/sagas';


export default function* rootSaga() {
  yield all([
      authSaga(),
      customersSaga(),
      // investorsSaga(),
      investmentsSaga(),
      projectsSaga(),
      teamSaga(),
      accountsSaga(),
      expensesSaga(),
      projectWiseInvestmentsSaga(),
      propertiesSaga(),
      postPropertiesSaga(),
      scriptsSaga(),
  ]);
}
