import {Typography} from '@mui/material';


export const PrimaryTypography = props => {
  const {align, classes, color, gutterBottom, textValue, variant} = props
  return (
    <Typography
      align={align || 'left'}
      classes={{root: classes}}
      color={color || '#FFFFFFCC'}
      gutterBottom={gutterBottom || false}
      width={350}
      variant={variant || 'h6'}
    >
      {textValue}
    </Typography>
  )
}
