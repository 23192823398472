import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import clsx from 'clsx';
import {Grid, Paper, TextField, Button, Typography, InputLabel, MenuItem, FormControl, Select} from '@mui/material';
import {makeStyles} from '@mui/styles';
import {Link} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import actions from '../../../redux/auth/actions';


const Register = () => {
    const [name, setName] = useState(null);
    const [lookingTo, setlookingTo] = useState(null);
    const [email, setEmailAddress] = useState(null);
    const [password, setPassword] = useState(null);
    const [contact, setContact] = useState(null);

    const dispatch = useDispatch();
    const handleSubmit = () =>{
        dispatch({ type: actions.CREATE_CUSTOMERS_START, payload:{name: name, lookingTo: lookingTo, email: email, password: password, contact: contact}});
    }
    const classes = customStyles();
    return(
        <>
            <Grid container className={classes.loginRoot}>
                <Grid item className={clsx(classes.gridSize, classes.fullWidth)}>
                    <Paper className={classes.cardSize}>
                        <Grid>
                            <Typography variant='h6' style={{color: '#00234B', fontSize:'16px', fontWeight: 400}}>
                                <span style={{color: '#FFC000', fontWeight: 600}}>A</span><span style={{color: '#0070C0', fontWeight: 600}}>C</span><span style={{color: '#00B050', fontWeight: 600}}>E</span> <span style={{color: '#FF5733', fontWeight: 600}}>MATRIX</span>
                            </Typography>
                            <Typography variant='h4' style={{color: '#00234B', fontWeight: 500, margin: 10, marginLeft: 0}}>Sign up</Typography>
                        </Grid>
                        <TextField
                            label='Name'
                            placeholder='Enter name'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <TextField
                            label='Email Address'
                            placeholder='Enter email address'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setEmailAddress(e.target.value)}
                        />
                        <TextField
                            label='Phone Number'
                            placeholder='Enter phone number'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setContact(e.target.value)}
                        />
                        <TextField
                            label='Password'
                            placeholder='Enter password'
                            type='password'
                            fullWidth
                            required
                            className={classes.margin8}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            type='submit'
                            color='primary'
                            variant='contained'
                            fullWidth
                            className={classes.postBtn}
                            onClick={() => handleSubmit()}
                        >
                            Join the Club
                        </Button>
                        <Typography variant='h6' style={{color: '#00234B', fontSize: '16px', marginTop: 5}}>By continuing, you agree to our <Link to={'/termsnconditions'}>Terms & Conditions</Link> and <Link to={'/privacypolicy'}>Privacy Policy</Link></Typography>
                        <Typography variant='h6' style={{color: '#00234B', fontSize: '16px', margin: '10px 0'}}>Already have an account? <Link to={'/login'}>Login</Link></Typography>
                        <Typography variant='h6' style={{color: '#00234B', fontSize: '16px'}}><Link to={'/'} className={clsx(classes.margin8a, classes.textDecoration)}>Back to Home</Link></Typography>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}

export default Register

const customStyles = makeStyles({
    title: {
        flexGrow: 1,
        color: '#FF5733',
        fontWeight: 600,
    },
    margin8: {
        margin: '10px 0',
    },
    fullWidth: {
        width: '100vw'
    },
    postBtn: {
        minWidth: 120,
        padding: '8px 20px',
        margin: '10px 0px',
        boxShadow: 'none',
        borderRadius: 20,
        color: '#FFF',
        backgroundColor: '#00B050',
        fontWeight: 600,

        '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#EBF5F0',
            color: '#00B050',
        },
    },
    loginRoot: {
        flexGrow: 1,
        padding: 20,
    },
    gridSize: {
        display: 'flex',
        justifyContent: 'center',
    },
    cardSize: {
        maxWidth: '400px',
        padding: 40,
        margin: 'auto',
        borderRadius: 20,
        boxShadow: '0px 10px 20px 0px rgba(0, 0, 0, 4%)',
    },
})
